import React from "react";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { t } = useTranslation();

  return (
    <div className="services">
      <div className="services__wrapper white">
        <div className="row">
          <div className="services__box ">
            <div className="services__box--content">
              <h1>{t("serve.title1")}</h1>
              <p>{t("serve.body")}</p>
            </div>
            <div className="services__box--image">
              <img src={`${process.env.PUBLIC_URL}/img/image.svg`} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="services__wrapper ">
        <div className="row">
          <div className="services__box ">
            <div className="services__box--image">
              <img src={`${process.env.PUBLIC_URL}/img/image.svg`} alt="" />
            </div>

            <div className="services__box--content">
              <h1> {t("serve.title2")}</h1>
              <p>{t("serve.body")}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="services__wrapper white">
        <div className="row">
          <div className="services__box ">
            <div className="services__box--content">
              <h1> {t("serve.title3")}</h1>
              <p>{t("serve.body")}</p>
            </div>
            <div className="services__box--image">
              <img src={`${process.env.PUBLIC_URL}/img/image.svg`} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="services__wrapper ">
        <div className="row">
          <div className="services__box ">
            <div className="services__box--image">
              <img src={`${process.env.PUBLIC_URL}/img/image.svg`} alt="" />
            </div>
            <div className="services__box--content">
              <h1> {t("serve.title4")}</h1>
              <p>{t("serve.body")}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="services__wrapper  white">
        <div className="row">
          <div className="services__box ">
            <div className="services__box--content">
              <h1>{t("serve.title5")}</h1>
              <p>{t("serve.body")}</p>
            </div>
            <div className="services__box--image">
              <img src={`${process.env.PUBLIC_URL}/img/image.svg`} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
