import React from "react";

import Form from "../components/ContactForm";

import { useTranslation } from "react-i18next";
export default function Contact() {
  const { t } = useTranslation();
  return (
    <div className="contact">
      <div className="row">
        <div className="contact__header">
          <div className="row row--no-padding">
            <div className="contact__header--content">
              <h3 className="page-title small">{t("contact.title")}</h3>
              <h3 className="page-title small">{t("contact.title2")}</h3>
              <h3 className="page-title small">
                {t("contact.title3")}
                <a href="#" className="btn btn-text hovered">
                  info@e-copon.com
                </a>
              </h3>
            </div>
          </div>
        </div>

        <div className="contact__info">
          <div className="contact__form ">
            <Form />
          </div>
          <div className="contact__detail ">
            <h1 className="contact__detail--title">
              {t("contact.تفاصيل أكثر")}
            </h1>
            <div className="contact__detail--box">
              <div className="icon">
                <img
                  src={`${process.env.PUBLIC_URL}/img/enail_con.svg`}
                  alt=""
                />
              </div>
              <div className="content">
                <h1>{t("contact.for_suggest")}</h1>
                <a href="" className="btn btn-text btn-text--black">
                  info@e-copon.com
                </a>
              </div>
            </div>

            <div className="contact__detail--box">
              <div className="icon">
                <img src={`${process.env.PUBLIC_URL}/img/helps.svg`} alt="" />
              </div>
              <div className="content">
                <h1>{t("contact.for_support")}</h1>
                <a href="" className="btn btn-text btn-text--black">
                  support@e-copon.com
                </a>
              </div>
            </div>
            <div className="contact__detail--box">
              <div className="icon">
                <img
                  src={`${process.env.PUBLIC_URL}/img/whatsapp_bussnis.svg`}
                  alt=""
                />
              </div>
              <div className="content">
                <h1>{t("contact.for_whatsapp")}</h1>
                <a href="" className="btn btn-text btn-text--black">
                  00556849541646498
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
