import Home from "./Views/Home";

import SuccessWithQr from "./Views/phase4/SuccessWithQR";

import Rules from "./Views/Rules";

import About from "./Views/About";

import Random from "./Views/phase4/Random";

import PersonalProfile from "./Views/phase4/PersonalProfile";

import LoginWithCodeForm from "./Views/phase4/LoginWithCodeForm";

import LoginWithPassword from "./Views/phase4/LoginWithPassword";

import LoginUser from "./Views/phase4/LoginUser";

import Success from "./Views/phase4/Success";

import RaceTitle from "./Views/phase4/RaceTitle";

import Reject from "./Views/phase4/Reject";

import CodeCheck from "./Views/phase4/CodeCheck";

import PolicyPage from "./Views/phase4/PolicyPage";

import UploadFiles from "./Views/phase4/UploadFiles";

import LoginAccountForm from "./Views/phase4/LoginAccountForm";

import NewAccountForm from "./Views/phase4/NewAccountForm";

import Competition from "./Views/phase4/Competition";

import RigesterCompany from "./Views/Phase2/RigesterCompany";

import LoginCompany from "./Views/Phase2/LoginCompany";

import Notifies from "./Views/Phase3/Notifies";

import Participations from "./Views/Phase3/Participations";

import Password from "./Views/Phase3/Password";

import MyData from "./Views/Phase3/MyData";

import RecreatePasword from "./Views/Phase2/RecreatePasword";

import RevertPasswordForm from "./Views/Phase2/RevertPasswordForm";

import Rigester from "./Views/Phase2/Rigester";

import FinishForm from "./Views/Phase2/FinishForm";

import PasswordForm from "./Views/Phase2/PasswordForm";

import CodeForm from "./Views/Phase2/CodeForm";

import AuthPage from "./Views/Phase2/AuthPage";

import Contact from "./Views/Contact";
import Services from "./Views/Services";
import Winners from "./Views/Winners";
import Challenges from "./Views/phase5/Challenges";
import SingleChallenge from "./Views/phase5/SingleChallenge";
import Result from "./Views/phase5/Result";
import Copons from "./Views/phase5/Copons";
import CoponDetails from "./Views/phase5/CoponDetails";
import Resturants from "./Views/phase5/Resturants";

var routes = [
  {
    path: "/home",
    name: "Home",
    rtlName: "الرئيسية",

    component: Home,
    layout: "/phase1",
  },
  {
    path: "/about",
    name: "About Us",
    rtlName: "من نحن",

    component: About,
    layout: "/phase1",
  },
  {
    path: "/services",
    name: "Serivces",
    rtlName: "خدماتنا",
    layout: "/phase1",

    component: Services,
  },
  {
    path: "/winners",
    name: "Winners",
    rtlName: "قائمة الفائزين",
    layout: "/phase1",

    component: Winners,
  },
  {
    path: "/contact",
    name: "Contact Us",
    rtlName: "تواصل بنا",
    layout: "/phase1",

    component: Contact,
  },
  {
    path: "/rules",
    name: "Terms",
    rtlName: "الشروط ",
    layout: "/phase1",

    component: Rules,
  },
];
var routes2 = [
  {
    path: "/auth",
    name: "Auth Page",
    rtlName: "بيانات الدخول",
    layout: "/phase2",

    component: AuthPage,
  },
  {
    path: "/code",
    name: "Code Form",
    rtlName: "فورم الكود",
    layout: "/phase2",

    component: CodeForm,
  },
  {
    path: "/password",
    name: "Code Form",
    rtlName: "فورم الكود",
    layout: "/phase2",

    component: PasswordForm,
  },
  {
    path: "/finish",
    name: "finish Form",
    rtlName: "فورم الانهاء",
    layout: "/phase2",

    component: FinishForm,
  },
  {
    path: "/register",
    name: "register Form",
    rtlName: "فورم التسجيل",
    layout: "/phase2",

    component: Rigester,
  },
  {
    path: "/revertpassword",
    name: "revertpassword Form",
    rtlName: "فورم ",
    layout: "/phase2",

    component: RevertPasswordForm,
  },
  {
    path: "/loginCompany",
    name: "loginCompany",
    rtlName: "loginCompany ",
    layout: "/phase2",

    component: LoginCompany,
  },
  {
    path: "/registerCompany",
    name: "registerCompany",
    rtlName: "registerCompany ",
    layout: "/phase2",

    component: RigesterCompany,
  },
  {
    path: "/recreate",
    name: "recreate Form",
    rtlName: "فورم ",
    layout: "/phase2",

    component: RecreatePasword,
  },
];
var routes3 = [
  {
    path: "/my-data",
    name: "My Data",
    rtlName: "بياناتي",

    component: MyData,
    layout: "/phase3",
  },
  {
    path: "/password",
    name: "",
    rtlName: "",
    layout: "/phase3",

    component: Password,
  },
  {
    path: "/participations",
    name: "My Posts",
    rtlName: "مشاركاتي",
    layout: "/phase3",

    component: Participations,
  },
  {
    path: "/notifies",
    name: "Notifies",
    rtlName: " الإشعارات",
    layout: "/phase3",

    component: Notifies,
  },
];
var routes4 = [
  {
    path: "/competition",
    name: "competition",
    rtlName: "المسابقة",

    component: Competition,
    layout: "/phase4",
  },
  {
    path: "/new-account",
    name: "new-account",
    rtlName: "المسابقة",

    component: NewAccountForm,
    layout: "/phase4",
  },
  {
    path: "/login-account",
    name: "login-account",
    rtlName: "Login Account",

    component: LoginAccountForm,
    layout: "/phase4",
  },
  {
    path: "/upload",
    name: "Upload",
    rtlName: "رفع الملفات",

    component: UploadFiles,
    layout: "/phase4",
  },
  {
    path: "/policy",
    name: "Policy",
    rtlName: "الخصوصية",

    component: PolicyPage,
    layout: "/phase4",
  },
  {
    path: "/codecheck",
    name: "code",
    rtlName: "code",

    component: CodeCheck,
    layout: "/phase4",
  },
  {
    path: "/reject",
    name: "reject",
    rtlName: "رفض",

    component: Reject,
    layout: "/phase4",
  },
  {
    path: "/success",
    name: "success",
    rtlName: "نجاح",

    component: Success,
    layout: "/phase4",
  },
  {
    path: "/success-with-qr",
    name: "success-with-qr",
    rtlName: "نجاح",

    component: SuccessWithQr,
    layout: "/phase4",
  },
  {
    path: "/racetitle",
    name: "race",
    rtlName: "المسابقة",

    component: RaceTitle,
    layout: "/phase4",
  },
  {
    path: "/login-user",
    name: "loginuser",
    rtlName: "loginl",

    component: LoginUser,
    layout: "/phase4",
  },
  {
    path: "/login-with-password",
    name: "LoginWithPassword",
    rtlName: "loginl",

    component: LoginWithPassword,
    layout: "/phase4",
  },
  {
    path: "/login-with-code-form",
    name: "LoginWithCodeForm",
    rtlName: "loginl",

    component: LoginWithCodeForm,
    layout: "/phase4",
  },
  {
    path: "/personal",
    name: "personal",
    rtlName: "loginl",

    component: PersonalProfile,
    layout: "/phase4",
  },
  {
    path: "/Random",
    name: "Random",
    rtlName: "loginl",

    component: Random,
    layout: "/phase4",
  },
];
var routes5 = [
  {
    path: "/copons/resturants/details",
    name: "Copon Details",
    rtlName: "تفاصيل الكوبون",

    component: CoponDetails,
    layout: "/phase5",
  },
  {
    path: "/copons/resturants",
    name: "Resturants",
    rtlName: "مطاعم",

    component: Resturants,
    layout: "/phase5",
  },
  {
    path: "/copons",
    name: "Copons",
    rtlName: "الكوبونات",

    component: Copons,
    layout: "/phase5",
  },
  {
    path: "/competitions/single/results",
    name: "Comp Results",
    rtlName: "نتائج المسابقة",

    component: Result,
    layout: "/phase5",
  },
  {
    path: "/competitions/single",
    name: "Comp Details",
    rtlName: "تفاصيل المسابقة",

    component: SingleChallenge,
    layout: "/phase5",
  },

  {
    path: "/competitions",
    name: "Competitions",
    rtlName: "المسابقات",

    component: Challenges,
    layout: "/phase5",
  },
];

export default { routes, routes2, routes3, routes4, routes5 };
