import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AuthPage() {
  const [text, setText] = React.useState("");
  const [showPassword, setshowPassword] = React.useState(false);

  const onchange = (e) => {
    setText(e.target.value);
  };

  const { t } = useTranslation();
  return (
    <div className="phone-form">
      <h1 className="auth__title">{t("auth.passord.title")}</h1>
      <h3 className="auth__subtitle">{t("auth.passord.sub")}</h3>

      <div className="auth__box">
        <div className="form__group ">
          <div className="row row--no-padding">
            <div className="pos-rel">
              <input
                onChange={onchange}
                value={text}
                type={showPassword ? "text" : "password"}
                className={`form__input has-icon has-left-icon circle ${
                  text.length > 4 ? "error" : text.length === 4 ? "success" : ""
                }`}
                placeholder={t("placeholders.passord")}
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/lock.svg`}
                alt="cobon back"
                className="form__icon"
              />
              {showPassword ? (
                <img
                  src={`${process.env.PUBLIC_URL}/img/eye_2.svg`}
                  alt="cobon back"
                  onClick={() => setshowPassword(!showPassword)}
                  className="form__icon--left"
                />
              ) : (
                <img
                  src={`${process.env.PUBLIC_URL}/img/eye.svg`}
                  alt="cobon back"
                  onClick={() => setshowPassword(!showPassword)}
                  className="form__icon--left"
                />
              )}
            </div>
          </div>
        </div>
        <div className="form__footer">
          <div className="flex-between">
            <div className=" text-right   col-padding-small flex-center">
              <Link
                to="/phase2/revertpassword"
                className="btn btn-text hovered btn--small"
              >
                {t("auth.passord.dont-remember")}
              </Link>
            </div>
            <div className=" text-left  col-padding-small">
              {text.length > 4 && (
                <span className="error-text no-margin">
                  {t("auth.passord.error")}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="form__group mg-top">
          <Link
            to="/phase2/finish"
            className={` btn bold custom-padding ${
              text.length < 1 || text.length > 9
                ? "btn--disabled"
                : "btn--green"
            } w-100`}
          >
            {t("placeholders.next")}{" "}
          </Link>
        </div>
      </div>
    </div>
  );
}
