/**
 * * Helper for managing scroll positions when navigating 
 */
import { useEffect, useRef,useState } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollIntoView = ({ children, location,history }) => {
  const prevLocation = useRef();
 
  useEffect(() => {
    
     if (prevLocation.current !== location.pathname) {
      if(history.action!=="POP"){
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: "smooth"
          });
      }
      prevLocation.current = location.pathname;
     

    }
   
  }, [location]);
  const alertUser = (e) => {
   
    alert('ddd')
  };
  return children;
};

export default withRouter(ScrollIntoView);