import React from "react";
import { useTranslation } from "react-i18next";

import Table from "../components/Table";
import Select from "../components/Select";
import i18next from "i18next";

export default function Winners() {
  const [empty, setempty] = React.useState(false);
  const options = [
    { value: "1", label: "فرغ" },
    { value: "2", label: "السحب الثاني" },
    { value: "3", label: "السحب الثالث" },
    { value: "4", label: "السحب الرابع" },

    { value: "5", label: "السحب الخامس" },
  ];

  const onChange = (e) => {
    if (e.value === "1") {
      setempty(true);
    } else {
      setempty(false);
    }
  };
  const { t } = useTranslation();

  const columns = [
    { name: "#", customName: `${t("winners.table.id")}` },
    { name: `${t("winners.table.name")}` },
    { name: `${t("winners.table.place")}` },
    { name: `${t("winners.table.award")}` },

    { name: `${t("winners.table.date")}` },
  ];
  // data of the table
  const data = [
    {
      "#": "1",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
    {
      "#": "2",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
    {
      "#": "3",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
    {
      "#": "4",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
    {
      "#": "5",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
    {
      "#": "6",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
    {
      "#": "7",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
    {
      "#": "8",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
    {
      "#": "9",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
    {
      "#": "10",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
  ];
  return (
    <div className="winners">
      <div className="row">
        <div className="winners__header">
          <div className="row row--no-padding">
            <div className="winners__header--content">
              <h1 className="page-title">{t("winners.title")}</h1>
              <h3 className="page-sub">{t("winners.sub")}</h3>
            </div>
          </div>
        </div>
        <div className="winners__wrapper">
          <div className="winners__filter">
            <div>
              <h6>{t("winners.select_title")}</h6>
              <Select
                className="filter-select"
                classNamePrefix="select"
                onChange={onChange}
                placeholder={t("winners.placeholder")}
                name="questions"
                options={options}
              />
            </div>
            <div>
              <h6>{t("winners.select_title")}</h6>
              <Select
                className="filter-select"
                classNamePrefix="select"
                placeholder={t("winners.placeholder1")}
                
                name="competetions"
                options={options}
              />
            </div>
            <div className="filter-btns">
              {empty ? (
                <button
                  disabled
                  className="btn btn--disabled has-md-radius  middle big-padding"
                >
                  {t("winners.search")}
                </button>
              ) : (
                <a className="btn btn--green middle big-padding has-md-radius">
                  {t("winners.search")}
                </a>
              )}
              <a className="btn btn--red middle has-icon has-md-radius fixed-width no-hover">
                <img
                  src={`${process.env.PUBLIC_URL}/img/close.svg`}
                  alt="close filters"
                />
                <span>{t("winners.result")}</span>
              </a>
            </div>
          </div>
          <div className="winners__table">
            <Table empty={empty} columns={columns} data={data} />
          </div>
        </div>
      </div>
    </div>
  );
}
