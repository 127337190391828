import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Select from "../../components/Select";
import EmailInput from "../../components/EmailInput";

export default function RevertPasswordForm() {
  const [text, setText] = React.useState("");
  const { t } = useTranslation();
  const onchange = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      setText(e.target.value);
    }
  };
  const options = [
    {
      value: "966",
      label: (
        <div className="select__withImg">
          <img
            src={`${process.env.PUBLIC_URL}/img/flag.svg`}
            height="28px"
            width="28px"
          />
          <span>+966</span>{" "}
        </div>
      ),
    },
    {
      value: "967",
      label: (
        <div className="select__withImg">
          <img
            src={`${process.env.PUBLIC_URL}/img/flag.svg`}
            height="28px"
            width="28px"
          />
          <span>+966</span>{" "}
        </div>
      ),
    },
    {
      value: "956",
      label: (
        <div className="select__withImg">
          <img
            src={`${process.env.PUBLIC_URL}/img/flag.svg`}
            height="28px"
            width="28px"
          />
          <span>+966</span>{" "}
        </div>
      ),
    },
    {
      value: "926",
      label: (
        <div className="select__withImg">
          <img
            src={`${process.env.PUBLIC_URL}/img/flag.svg`}
            height="28px"
            width="28px"
          />
          <span>+966</span>{" "}
        </div>
      ),
    },
    {
      value: "964",
      label: (
        <div className="select__withImg">
          <img
            src={`${process.env.PUBLIC_URL}/img/flag.svg`}
            height="28px"
            width="28px"
          />
          <span>+966</span>{" "}
        </div>
      ),
    },
  ];

  const onChange = (e) => {};
  return (
    <div className="phone-form">
      <h1 className="auth__title">{t("auth.revert.title")} </h1>
      <h3 className="auth__subtitle">{t("auth.revert.sub")}</h3>

      <div className="auth__box">
        <div className="form__group ">
          <div className="row row--no-padding">
            <div className="pos-rel">
              <EmailInput icon={true} placeholder={t("placeholders.email")} />
            </div>
          </div>
        </div>
        <div className="form__lines">
          <div></div>
          <span>{t("auth.or")}</span>
          <div></div>
        </div>
        <div className="form__group mg-top big-btm">
          <div className="row row--no-padding auth__group">
            <div className="input pos-rel">
              <input
                onChange={onchange}
                value={text}
                type="text"
                className={`form__input has-icon circle ${
                  text.length > 9 ? "error" : text.length === 9 ? "success" : ""
                }`}
                placeholder={t("placeholders.tele")}
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/phone.svg`}
                alt="cobon back"
                className="form__icon"
              />
            </div>
            <div className="select-wrapper">
              <Select
                className="form-select"
                classNamePrefix="select"
                onChange={onChange}
                placeholder="اختر كود"
                defaultValue={options[0]}
                name="questions"
                options={options}
              />
            </div>
          </div>
        </div>

        <div className="form__group no-margin">
          <Link
            to="/phase2/recreate"
            className={` btn bold custom-padding ${
              text.length < 1 ? "btn--disabled" : "btn--green"
            } w-100`}
          >
            {t("placeholders.send")}{" "}
          </Link>
        </div>
      </div>
    </div>
  );
}
