import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function CoponDetails() {
  const { t } = useTranslation();

  return (
    <div className="bottom-distance">
      <div className="row">
        <div className="comp-details big">
          <div className="comp-details__body">
            <div className="comp-details__body--data">
              <div className="comp-details__body--img">
                <img src={`${process.env.PUBLIC_URL}/img/sympol.svg`} alt="" />
              </div>
              <div className="comp-details__body--text">
                <h1>{t("phase5.comp.title")}</h1>
                <p>{t("phase5.comp.copon")}: </p>
                <div className="challenges__card--content details">
                  <h4 className="price">
                    <span className="main">
                      400 <span>{t("phase5.card.coin")} </span>
                    </span>
                    <span className="text">{t("phase5.card.instead")} </span>
                    <span className="red">
                      500 <span>{t("phase5.card.coin")}</span>
                    </span>
                  </h4>
                </div>
                <p className="mg-top-big">{t("phase5.comp.address")}: </p>
                <p>
                  <strong className="font-big">
                    {t("phase5.comp.street")}:{" "}
                  </strong>
                </p>
                <p className="mg-top-big">{t("phase5.comp.destribute")}: </p>
                <p>
                  <strong className="font-big">e-copon</strong>
                </p>

                <Link
                  to="/phase5/competitions/single/results"
                  className={` btn middle  btn--green mg-top-bigger display-in-bl
           
             `}
                >
                  {t("phase5.comp.btn")}{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
