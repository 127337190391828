import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Modal from "../../components/Modal";
import Select from "../../components/Select";
import DatePicker from "../../components/DatePicker";
import EmailInput from "../../components/EmailInput";
import PasswordInput from "../../components/PasswordInput";



import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
export default function LoginUser4() {
  const [text, setText] = React.useState("");
  const [checked, setchecked] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(true);
  const [showPassword, setshowPassword] = React.useState(false);
  const [showPasswordConfirm, setshowPasswordConfirm] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const [phone, setphone] = React.useState("");
const {t} = useTranslation()
  return (
    <div className="">
      <Modal
        closeModal={closeModal}
        openModal={openModal}
        modalIsOpen={modalIsOpen}
        title= {t('phase4.comp.personal')}
      >
        <div className="new-account-form">
          <form action="">
            <div className="form__group sm-btm">
              <div className="row row--no-padding">
                <div className="pos-rel">
                  <input
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    type="text"
                    className={`form__input circle `}
                    placeholder= {t('placeholders.first_name')}
                  />
                </div>
              </div>
            </div>
         <div className="form__group sm-btm">
              <div className="row row--no-padding">
                <div className="pos-rel">
                 <EmailInput
                  icon={true}
                  placeholder= {t('placeholders.email')}
                 />
                </div>
              </div>
            </div>
               <div className="form__group sm-btm">
              <div className="row row--no-padding">
                <div className=" pos-rel">
                  <PasswordInput 
                  placeholder= {t('placeholders.passord')}
                  />
                </div>
              </div>
            </div>
            <div className="form__group big-btm">
              <div className="row row--no-padding">
                <div className=" pos-rel">
                <PasswordInput 
                  placeholder= {t('placeholders.confirm')}
                  />
                </div>
              </div>
            </div>
              <div className="form__group ">
              <Link
                to="/phase4/random"
                className={` btn bold custom-padding ${
                  !checked ? "btn--disabled" : "btn--green"
                } w-100`}
              >
                {t('placeholders.follow')}{" "}
              </Link>
            </div>
          </form>
        </div>
      </Modal>
      <div className="row">
        <div className="comp-details big"
        onClick={openModal}
        >
          <div className="comp-details__body">
            <div className="comp-details__body--data">
              <div className="comp-details__body--img"></div>
              <div className="comp-details__body--text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
