import React from "react";
import { Link, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../components/Navbar";
import Hero from "../components/Hero";

import { Route, Switch, Redirect } from "react-router-dom";

export default withRouter(function Phase3({ routes, children, location }) {
  const { t } = useTranslation();
  return (
    <div>
      <Navbar phase3={true} routes={routes} />

      <Hero routes={routes} />
      <div className="page-body">
        <Switch>
          {routes.map((prop, key) => {
            return (
              <Route
                path={prop.layout + prop.path}
                render={(props) => (
                  <prop.component {...props} title={`${prop.rtlName}`} />
                )}
                key={key}
              />
            );
          })}
          <Redirect from="/phase3" to="/phase3/my-data" />
        </Switch>
      </div>
      <div
        className={`page-footer ${
          location.pathname === "/phase1/services" ? "white" : ""
        }`}
      >
        <footer
          className="footer"
          style={{
            background: `url(${process.env.PUBLIC_URL}/img/footer.png)  no-repeat center center /cover`,
          }}
        >
          <div className="row">
            <div className="app ">
              <div className=" app__text">
                <h2>{t("footer.app_title")}</h2>
                <p>{t("footer.app_desc")}</p>
              </div>
              <div className="">
                <div className="app__btns">
                  <a className="btn btn--white no-hover has-long-radius img-only pd-0">
                   
                    <img
                      src={`${process.env.PUBLIC_URL}/img/google_paly.svg`}
                      alt="download ecobon"
                    />
                  </a>
                  <a className="btn btn--white no-hover has-long-radius img-only  pd-0">
                   
                    <img
                      src={`${process.env.PUBLIC_URL}/img/app_store.svg`}
                      alt="download ecobon"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="info">
              <div className="right">
                <h6>{t("footer.text")}</h6>
                <Link to="/phase4/policy" className="btn btn-text big">
                  {t("footer.policy_link")}
                </Link>
                <span>|</span>
                <Link to="/phase1/rules" className="btn btn-text big">
                  {" "}
                  {t("footer.rules")}
                </Link>
              </div>
              <div className="left">
                <h6>{t("footer.followUs")} </h6>
                <ul>
                  <li>
                    <a href="#">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/facebook.svg`}
                        alt="download ecobon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/twitter.svg`}
                        alt="download ecobon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/whatsapp.svg`}
                        alt="download ecobon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/instgram.svg`}
                        alt="download ecobon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/linkedIn.svg`}
                        alt="download ecobon"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <hr />

            <div className="info">
              <div className="right">
                <p>
                  جميع الحقوق محفوظة {new Date().getFullYear()} &copy; Copon
                  حقوق الطبع والنشر
                </p>
              </div>
              <div className="left">
                <p>
                  {t("footer.footer_designer")}:{" "}
                  <a href="" className="btn btn-text">
                    uxitar
                  </a>
                </p>
              </div>
            </div>
          </div>{" "}
        </footer>
      </div>
    </div>
  );
});
