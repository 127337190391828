import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Modal from "../../components/Modal";
import UploadFile from "../../components/UploadFile";

export default function UploadFiles() {
 
  const [checked, setchecked] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(true);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  
  const { t } = useTranslation();
  return (
    <div className="competition">
      <Modal
        closeModal={closeModal}
        openModal={openModal}
        modalIsOpen={modalIsOpen}
        title={t("phase4.upload.title")}
      >
        <div className="new-account-form upload">
          <form action="">
            <div>
              <h6>{t("phase4.upload.sub")}</h6>

              <UploadFile />
            </div>
            <div>
              <h6>{t("phase4.upload.sub2")}</h6>

              <UploadFile />
            </div>
            <div className="form__group mg-top md-btm2">
              <div className="row row--no-padding">
                <div className="pos-rel">
                  <input
                    type="checkbox"
                    onChange={(e) => setchecked(e.currentTarget.checked)}
                    id="policy"
                    name="policy"
                    checked={checked}
                    // value={checked}
                  />
                  <label for="policy">
                    <div className="check">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/check_white.svg`}
                        height="11px"
                        width="11px"
                      />
                    </div>
                    <span style={{ fontSize: "1.5rem", marginLeft: ".2rem" }}>
                      {t("phase4.agree")}
                    </span>{" "}
                    <Link
                      style={{ fontSize: "1.5rem" }}
                      to="/phase4/policy"
                      className="btn btn-text hovered"
                    >
                      {t("phase4.policy")}
                    </Link>
                  </label>
                </div>
              </div>
            </div>
            <div className="form__group ">
              <Link
                to="/phase4/codecheck"
                className={` btn bold custom-padding ${
                  !checked ? "btn--disabled" : "btn--green"
                } w-100`}
              >
                {t("phase4.upload.btn")}{" "}
              </Link>
            </div>
          </form>
        </div>
      </Modal>
      <div className="row">
        <div className="comp-details big" onClick={openModal}>
          <div className="comp-details__body">
            <div className="comp-details__body--data">
              <div className="comp-details__body--img"></div>
              <div className="comp-details__body--text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
