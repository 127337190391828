import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Select from "../../components/Select";
import EmailInput from "../../components/EmailInput";

export default function Rigester() {
  const [text, setText] = React.useState("");
  const [showPassword, setshowPassword] = React.useState(false);
  const [showPasswordConfirm, setshowPasswordConfirm] = React.useState(false);
  const { t } = useTranslation();
  const options = [
    { value: "1", label: "فرغ" },
    { value: "2", label: "السحب الثاني" },
    { value: "3", label: "السحب الثالث" },
    { value: "4", label: "السحب الرابع" },

    { value: "5", label: "السحب الخامس" },
  ];

  return (
    <div className="phone-form">
      <h1 className="auth__title">{t("auth.register.title")}</h1>
      <h3 className="auth__subtitle">{t("auth.register.sub")}</h3>

      <div className="auth__box">
        <form action="">
          <div className="form__group big-btm">
            <div className="row row--no-padding">
              <div className="pos-rel">
                <input
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  type="text"
                  className={`form__input has-icon  circle `}
                  placeholder={t("placeholders.full-name")}
                  required
                  id="a-name"
                />
                <img
                  src={`${process.env.PUBLIC_URL}/img/user_2.svg`}
                  alt="cobon user name"
                  className="form__icon"
                />
              </div>
            </div>
          </div>
          <div className="form__footer">
            <div className="d-center">
              <div className=" text-right   col-padding-small flex-center">
                <p className="">{t("auth.register.form-header")}</p>
              </div>
            </div>
          </div>
          <div className="form__group mg-top">
            <div className="row row--no-padding">
              <div className="col-1-of-2 col-padding-small pos-rel">
                <input
                  type="text"
                  className={`form__input has-icon  circle `}
                  placeholder={t("placeholders.id_card")}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/img/id_card.svg`}
                  alt="cobon user email"
                  className="form__icon"
                />
              </div>
              <div className="col-1-of-2 col-padding-small pos-rel">
                <EmailInput placeholder={t("placeholders.email")} icon={true} />
              </div>
            </div>
          </div>

          <div className="form__group ">
            <div className="row row--no-padding">
              <div className="col-1-of-2 col-padding-small pos-rel">
                <Select
                  className="filter-select in-form has-icon"
                  classNamePrefix="select"
                  onChange={() => {}}
                  placeholder={t("placeholders.sex")}
                  name="sex"
                  options={options}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/img/gender.svg`}
                  alt="cobon back"
                  className="form__icon"
                />
              </div>
              <div className="col-1-of-2 col-padding-small pos-rel">
                <Select
                  className="filter-select in-form"
                  classNamePrefix="select"
                  onChange={() => {}}
                  placeholder={t("placeholders.nationality")}
                  name="nationality"
                  options={options}
                />
              </div>
            </div>
          </div>

          <div className="form__group ">
            <div className="row row--no-padding">
              <div className="col-1-of-2 col-padding-small pos-rel">
                <Select
                  className="filter-select in-form"
                  classNamePrefix="select"
                  onChange={() => {}}
                  placeholder={t("placeholders.country")}
                  name="country"
                  options={options}
                />
              </div>
              <div className="col-1-of-2 col-padding-small pos-rel">
                <Select
                  className="filter-select in-form"
                  classNamePrefix="select"
                  onChange={() => {}}
                  placeholder={t("placeholders.city")}
                  name="city"
                  options={options}
                />
              </div>
            </div>
          </div>

          <div className="form__group ">
            <div className="row row--no-padding">
              <div className="col-1-of-2 col-padding-small pos-rel">
                <input
                  type={showPassword ? "text" : "password"}
                  className={`form__input has-icon has-left-icon circle ${
                    text.length > 4
                      ? "error"
                      : text.length === 4
                      ? "success"
                      : ""
                  }`}
                  placeholder={t("placeholders.passord")}
                  required
                  id="a-phone"
                />
                <img
                  src={`${process.env.PUBLIC_URL}/img/lock.svg`}
                  alt="cobon back"
                  className="form__icon"
                />
                {showPassword ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/img/eye_2.svg`}
                    alt="cobon back"
                    onClick={() => setshowPassword(!showPassword)}
                    className="form__icon--left"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/img/eye.svg`}
                    alt="cobon back"
                    onClick={() => setshowPassword(!showPassword)}
                    className="form__icon--left"
                  />
                )}
              </div>
              <div className="col-1-of-2 col-padding-small pos-rel">
                <input
                  type={showPasswordConfirm ? "text" : "password"}
                  className={`form__input has-icon has-left-icon circle ${
                    text.length > 4
                      ? "error"
                      : text.length === 4
                      ? "success"
                      : ""
                  }`}
                  placeholder={t("placeholders.confirm")}
                  required
                  id="a-phone"
                />
                <img
                  src={`${process.env.PUBLIC_URL}/img/lock.svg`}
                  alt="cobon back"
                  className="form__icon"
                />
                {showPasswordConfirm ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/img/eye_2.svg`}
                    alt="cobon back"
                    onClick={() => setshowPasswordConfirm(!showPasswordConfirm)}
                    className="form__icon--left"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/img/eye.svg`}
                    alt="cobon back"
                    onClick={() => setshowPasswordConfirm(!showPasswordConfirm)}
                    className="form__icon--left"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="form__group mg-top-big">
            <Link
              to="/phase2/revertpassword"
              className={` btn bold custom-padding ${
                text.length < 1 ? "btn--disabled" : "btn--green"
              } w-100`}
            >
              {t("placeholders.next")}{" "}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
