import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Select from "../../components/Select";
import EmailInput from "../../components/EmailInput";

export default function LoginCompany() {
  const [text, setText] = React.useState("");

  const onchange = (e) => {
    setText(e.target.value);
  };
  const { t } = useTranslation();

  return (
    <div className="login-company-form">
      <h1 className="auth__heading">{t("auth.heading")}</h1>
      <h1 className="auth__title"> {t("auth.login")}</h1>
      <h3 className="auth__subtitle"> {t("auth.please-login")}</h3>

      <div className="auth__box">
        <div className="form__group ">
          <div className="row row--no-padding ">
            <div className="input pos-rel">
              <input
                onChange={onchange}
                value={text}
                type="text"
                className={`form__input has-icon circle ${
                  text.length > 9 ? "error" : text.length === 9 ? "success" : ""
                }`}
                placeholder={t("placeholders.username")}
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/user_2.svg`}
                alt="cobon back"
                className="form__icon"
              />
            </div>
          </div>
        </div>
        <div className="form__group big-btm">
          <div className="row row--no-padding ">
            <div className="input pos-rel">
              <EmailInput
                icon={true}
                placeholder={t("placeholders.username")}
              />
            </div>
          </div>
        </div>

        <div className="form__group btm">
          <Link
            to="/phase2/code"
            className={` btn bold custom-padding ${
              text.length < 1 || text.length > 9
                ? "btn--disabled"
                : "btn--green"
            } w-100`}
          >
            {t("placeholders.next")}{" "}
          </Link>
        </div>
        <div className="form__footer pd-top">
          <div className="flex-center wrap_center">
            <div className=" col-padding-small">
              <span>{t("auth.noAccount")}</span>
            </div>
            <div className=" col-padding-small">
              <Link
                to="/phase2/registerCompany"
                className="btn btn-text hovered"
              >
                {t("auth.create")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
