import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Modal from "../../components/Modal";
import Select from "../../components/Select";
import UploadFile from "../../components/UploadFile";

export default function SuccessWithQR() {
  const [modalIsOpen, setIsOpen] = React.useState(true);
  const { t } = useTranslation();
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="">
      <Modal
        closeModal={closeModal}
        openModal={openModal}
        modalIsOpen={modalIsOpen}
        title={t("phase4.success")}
      >
        <div className="new-account-form message">
          <div className="message-box no-icon">
            <div
              className="flex-center"
              style={{
                width: "100%",
                height: "6rem",
                background: "#f0f0f1",
                borderRadius: "8px",
                marginBottom: "2rem",
              }}
            >
              <img
                style={{
                  width: "3.4rem",
                  height: "3.4rem",
                  marginBottom: "0",
                }}
                src={`${process.env.PUBLIC_URL}/img/sympol.svg`}
                alt="message"
              />
            </div>
            <Link to="/phase4/racetitle" className="btn btn--green w-100">
              {t("phase4.btn_agree")}
            </Link>
          </div>
        </div>
      </Modal>
      <div className="row">
        <div className="comp-details big" onClick={openModal}>
          <div className="comp-details__body">
            <div className="comp-details__body--data">
              <div className="comp-details__body--img"></div>
              <div className="comp-details__body--text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
