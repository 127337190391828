import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Modal from "../../components/Modal";

export default function LoginUser() {
  const [text, setText] = React.useState("");
  const [modalIsOpen, setIsOpen] = React.useState(true);
  const [showPassword, setshowPassword] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const { t } = useTranslation();
 

  return (
    <div className="competition">
      <Modal
        closeModal={closeModal}
        openModal={openModal}
        modalIsOpen={modalIsOpen}
        title={t("navbar:sign")}
      >
        <div className="new-account-form">
          <form action="">
            <div className="form__group ">
              <div className="row row--no-padding">
                <div className="pos-rel">
                  <input
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                    value={text}
                    type={showPassword ? "text" : "password"}
                    className={`form__input has-icon has-left-icon circle ${
                      text.length > 4
                        ? "error"
                        : text.length === 4
                        ? "success"
                        : ""
                    }`}
                    placeholder={t("placeholders.passord")}
                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/img/lock.svg`}
                    alt="cobon back"
                    className="form__icon"
                  />
                  {showPassword ? (
                    <img
                      src={`${process.env.PUBLIC_URL}/img/eye_2.svg`}
                      alt="cobon back"
                      onClick={() => setshowPassword(!showPassword)}
                      className="form__icon--left"
                    />
                  ) : (
                    <img
                      src={`${process.env.PUBLIC_URL}/img/eye.svg`}
                      alt="cobon back"
                      onClick={() => setshowPassword(!showPassword)}
                      className="form__icon--left"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="form__footer">
              <div className="flex-between">
                <div className=" text-right   col-padding-small flex-center">
                  <Link
                    to="/phase4/login-user"
                    className="btn btn-text hovered btn--small"
                  >
                    {t("auth.passord.dont-remember")}
                  </Link>
                </div>
                <div className=" text-left  col-padding-small">
                  {text.length > 4 && (
                    <span className="error-text no-margin">
                      {t("auth.passord.error")}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="form__group mg-top">
              <Link
                to="/phase4/login-with-code-form"
                className={` btn bold custom-padding ${
                  !text.length > 0 ? "btn--disabled" : "btn--green"
                } w-100`}
              >
                {t("placeholders.next")}{" "}
              </Link>
            </div>
          </form>
        </div>
      </Modal>
      <div className="row">
        <div className="comp-details big" onClick={openModal}>
          <div className="comp-details__body">
            <div className="comp-details__body--data">
              <div className="comp-details__body--img"></div>
              <div className="comp-details__body--text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
