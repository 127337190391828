import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Modal from "../../components/Modal";
import Select from "../../components/Select";
import DatePicker from "../../components/DatePicker";

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
export default function NewAccountForm() {
  const [text, setText] = React.useState("");
  const [checked, setchecked] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(true);
  const [showPassword, setshowPassword] = React.useState(false);
  const [showPasswordConfirm, setshowPasswordConfirm] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const [phone, setphone] = React.useState("");
const {t} = useTranslation()
  const onchangephone = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      setphone(e.target.value);
    }
  };
  const options = [
    {
      value: "966",
      label: (
        <div className="select__withImg">
          <img
            src={`${process.env.PUBLIC_URL}/img/flag.svg`}
            height="28px"
            width="28px"
          />
          <span>+966</span>{" "}
        </div>
      ),
    },
    {
      value: "967",
      label: (
        <div className="select__withImg">
          <img
            src={`${process.env.PUBLIC_URL}/img/flag.svg`}
            height="28px"
            width="28px"
          />
          <span>+966</span>{" "}
        </div>
      ),
    },
    {
      value: "956",
      label: (
        <div className="select__withImg">
          <img
            src={`${process.env.PUBLIC_URL}/img/flag.svg`}
            height="28px"
            width="28px"
          />
          <span>+966</span>{" "}
        </div>
      ),
    },
    {
      value: "926",
      label: (
        <div className="select__withImg">
          <img
            src={`${process.env.PUBLIC_URL}/img/flag.svg`}
            height="28px"
            width="28px"
          />
          <span>+966</span>{" "}
        </div>
      ),
    },
    {
      value: "964",
      label: (
        <div className="select__withImg">
          <img
            src={`${process.env.PUBLIC_URL}/img/flag.svg`}
            height="28px"
            width="28px"
          />
          <span>+966</span>{" "}
        </div>
      ),
    },
  ];
  const options2 = [
    { value: "1", label: "فرغ" },
    { value: "2", label: "السحب الثاني" },
    { value: "3", label: "السحب الثالث" },
    { value: "4", label: "السحب الرابع" },

    { value: "5", label: "السحب الخامس" },
  ];
  return (
    <div className="competition">
      <Modal
        closeModal={closeModal}
        openModal={openModal}
        modalIsOpen={modalIsOpen}
        title={t('navbar:sign_up')}
      >
        <div className="new-account-form">
          <form action="">
            <div className="form__group sm-btm">
              <div className="row row--no-padding">
                <div className="pos-rel">
                  <input
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    type="text"
                    className={`form__input circle `}
                    placeholder={t('placeholders.first_name')}
                  />
                </div>
              </div>
            </div>
            <div className="form__group sm-btm">
              <div className="row row--no-padding">
                <div className="pos-rel">
                  <input
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    type="text"
                    className={`form__input circle `}
                    placeholder={t('placeholders.last_name')}

                  />
                </div>
              </div>
            </div>
            <div className="form__group sm-btm">
              <div className="row row--no-padding auth__group">
                <div className="input pos-rel">
                  <input
                    onChange={onchangephone}
                    value={phone}
                    type="text"
                    className={`form__input has-icon circle ${
                      phone.length > 9
                        ? "error"
                        : phone.length === 9
                        ? "success"
                        : ""
                    }`}
                    placeholder={t('placeholders.tele')}

                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/img/phone.svg`}
                    alt="cobon back"
                    className="form__icon"
                  />

                
                </div>
                <div className="select-wrapper">
                  <Select
                    className="form-select"
                    classNamePrefix="select"
                    onChange={() => {}}
                    placeholder="اختر كود"
               
                    defaultValue={options[0]}
                    name="questions"
                    options={options}
                  />
                </div>
              </div>
            </div>
            <div className="form__group sm-btm">
              <div className="row row--no-padding">
                <div className=" pos-rel">
                  {/* <Select
                    className="filter-select in-form has-icon"
                    classNamePrefix="select"
                    onChange={() => {}}
                    placeholder="تاريخ الميلاد"
                    name="date"
                    options={options2}
                  /> */}
                  <DatePicker />
                </div>
              </div>
            </div>
            <div className="form__group sm-btm">
              <div className="row row--no-padding">
                <div className="pos-rel">
                  <input
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    type="email"
                    className={`form__input circle has-icon`}
                    placeholder={t('placeholders.email')}

                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/img/email.svg`}
                    alt="cobon user email"
                    className="form__icon"
                  />
                </div>
              </div>
            </div>
            <div className="form__group sm-btm">
              <div className="row row--no-padding">
                <div className=" pos-rel">
                  <Select
                    className="filter-select in-form "
                    classNamePrefix="select"
                    onChange={() => {}}
                    placeholder={t('placeholders.nationality')}

                    name="nationality"
                    options={options2}
                  />
                </div>
              </div>
            </div>
            <div className="form__group sm-btm">
              <div className="row row--no-padding">
                <div className="pos-rel">
                  <input
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    type="text"
                    className={`form__input circle has-icon`}
                    placeholder={t('placeholders.card_num')}

                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/img/id_card.svg`}
                    alt="cobon user id card"
                    className="form__icon"
                  />
                </div>
              </div>
            </div>
            <div className="form__group sm-btm">
              <div className="row row--no-padding">
                <div className=" pos-rel">
                  <input
                    type={showPassword ? "text" : "password"}
                    className={`form__input has-icon has-left-icon circle `}
                    placeholder={t('placeholders.passord')}

                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/img/lock.svg`}
                    alt="cobon back"
                    className="form__icon"
                  />
                  {showPassword ? (
                    <img
                      src={`${process.env.PUBLIC_URL}/img/eye_2.svg`}
                      alt="cobon back"
                      onClick={() => setshowPassword(!showPassword)}
                      className="form__icon--left"
                    />
                  ) : (
                    <img
                      src={`${process.env.PUBLIC_URL}/img/eye.svg`}
                      alt="cobon back"
                      onClick={() => setshowPassword(!showPassword)}
                      className="form__icon--left"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="form__group md-btm2">
              <div className="row row--no-padding">
                <div className=" pos-rel">
                  <input
                    type={showPasswordConfirm ? "text" : "password"}
                    className={`form__input has-icon has-left-icon circle `}
                    placeholder={t('placeholders.confirm')}

                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/img/lock.svg`}
                    alt="cobon back"
                    className="form__icon"
                  />
                  {showPasswordConfirm ? (
                    <img
                      src={`${process.env.PUBLIC_URL}/img/eye_2.svg`}
                      alt="cobon back"
                      onClick={() =>
                        setshowPasswordConfirm(!showPasswordConfirm)
                      }
                      className="form__icon--left"
                    />
                  ) : (
                    <img
                      src={`${process.env.PUBLIC_URL}/img/eye.svg`}
                      alt="cobon back"
                      onClick={() =>
                        setshowPasswordConfirm(!showPasswordConfirm)
                      }
                      className="form__icon--left"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="form__group md-btm2">
              <div className="row row--no-padding">
                <div className="pos-rel">
                  <input
                    type="checkbox"
                    onChange={(e)=>setchecked(e.currentTarget.checked)}
                    id="fruit4"
                    name="fruit-4"
                    checked={checked}
                   // value={checked}
                  />
                  <label for="fruit4">
                      <div className="check">
                      <img
            src={`${process.env.PUBLIC_URL}/img/check_white.svg`}
            height="11px"
            width="11px"
          />
                      </div>
                    <span style={{ fontSize: "1.5rem", marginLeft: ".2rem" }}>
                    {t('phase4.agree')}

                    </span>{" "}
                    <Link
                      style={{ fontSize: "1.5rem" }}
                      to="/phase4/policy"
                      className="btn btn-text hovered"
                    >
                       {t('phase4.policy')}
                    </Link>
                  </label>
                </div>
              </div>
            </div>
            <div className="form__group ">
              <Link
                to="/phase4/login-account"
                className={` btn bold custom-padding ${
                  !checked ? "btn--disabled" : "btn--green"
                } w-100`}
              >
                 {t('navbar:sign_up')}{" "}
              </Link>
            </div>
          </form>
        </div>
      </Modal>
      <div className="row">
        <div className="comp-details big"
        onClick={openModal}
        >
          <div className="comp-details__body">
            <div className="comp-details__body--data">
              <div className="comp-details__body--img"></div>
              <div className="comp-details__body--text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
