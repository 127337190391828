import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function RaceTitle() {
  const { t } = useTranslation();
  return (
    <div className="row">
      <div className="race">
        <h1>{t("phase4.comp.title")}</h1>
        <div className="race__box">
          <img
            src={`${process.env.PUBLIC_URL}/img/grey-img.png`}
            alt="grey-img"
          />
          <Link
            className="btn btn--yellow middle has-shadow"
            to="/phase4/login-user"
          >
            {t("phase4.comp.btn")}
          </Link>
        </div>
      </div>
    </div>
  );
}
