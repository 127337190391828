import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Slider from "../../components/RandomSlider";
import Modal from "../../components/Modal";

export default function Random() {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [score, setScore] = React.useState(0);
  const { t } = useTranslation();
  function openModal(s) {
    setIsOpen(true);
    setScore(s);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div>
      <div className="race caro">
        <Modal
          closeModal={closeModal}
          openModal={openModal}
          modalIsOpen={modalIsOpen}
          title={t("phase4.comp.success")}
        >
          <div className="new-account-form message">
            <div className="message-box">
              <img
                src={`${process.env.PUBLIC_URL}/img/check_green.svg`}
                alt="message"
              />
              <p>
                {t("phase4.comp.name")} {score}
              </p>
            </div>
          </div>
        </Modal>
        <div className="row">
          <h1>{t("phase4.comp.title2")}</h1>
        </div>
        <div className="race__slide-box">
          <Slider btn_title={t("phase4.comp.btn")} openSuccess={openModal} />
        </div>
      </div>
    </div>
  );
}
