/***
 * * An officail translation file initializer 
 */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";

import translationEN from "./assets/locales/en/translation.json";
import navbarEN from "./assets/locales/en/navbar.json";
import translationAR from "./assets/locales/ar/translation.json";
import navbarAR from "./assets/locales/ar/navbar.json";

const fallbackLng = ["ar"];
const availableLanguages = ["en", "ar"];

const resources = {
  en: {
    translation: translationEN,
    navbar:navbarEN
  },
  ar: { 
    translation: translationAR,
    navbar:navbarAR

  },
 
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    interpolation: {
        format: function (value, format, lng) {
          if (value instanceof Date) return moment(value).format(format);
          return value;
        }
      },
    detection: {
      checkWhitelist: true
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
