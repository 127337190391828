import React from 'react'
import Select, { components } from 'react-select';
import i18next from 'i18next';


export default function SelectC({options,onChange,placeholder,className,name,...rest}) {
   
      const colourStyles = {
      
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
         
          return {
            ...styles,
            backgroundColor: isSelected ? "#0ab4da" :null,
            color:isSelected ? '#fff' : '',
            
            cursor:  'grabbing',
            "&:hover": {
                // Overwrittes the different states of border
                backgroundColor: isSelected ? "#0ab4da" : "#0ab4da" ,
                color:'#fff'
              },
           
          };
        },
        // menu: (provided, state) => ({
        //   ...provided,
         
        //   minHeight: '171px',
        //   height: '171px',
         
        // }),
    }
    const DropdownIndicator = props => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <img src={`${process.env.PUBLIC_URL}/img/${props.selectProps.menuIsOpen ? "chevron-up" : "chevron-down"}.svg`} />
          </components.DropdownIndicator>
        )
      );
    };
    return (
        <Select
        components={{ DropdownIndicator }}
        className={className}
        classNamePrefix="select"
        onChange={onChange}
        placeholder={placeholder}
     //   isRtl={true}
     isRtl={i18next.languages[0] === "en" ? false : true}

        isSearchable={false}
        styles={colourStyles}
        name={name}
        options={options}
        {...rest}
      />
    )
}
