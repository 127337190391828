import Phase2 from "./Layouts/Phase2";

import Phase5 from "./Layouts/Phase5";


import Phase4 from "./Layouts/Phase4";


import ScrollPos from "./helpers/ScrollPos";

import Phase3 from "./Layouts/Phase3";

import r from "./routes";

import Main from "./Layouts/Main";

import { Route, Switch, Redirect } from "react-router-dom";

function App() {
  const switchRoutes = (
    <Switch>
      {r.routes.map((prop, key) => {
        return <Route path={prop.path} component={prop.component} key={key} />;
      })}
      <Redirect from="/" to="/home" />
    </Switch>
  );

  return (
    <div>
      <ScrollPos>
        <Switch>
        <Route
            path="/phase5"
            render={(props) => <Phase5 {...props} routes={r.routes5} />}
          />
            <Route
            path="/phase4"
            render={(props) => <Phase4 {...props} routes={r.routes4} />}
          />
           <Route
            path="/phase3"
            render={(props) => <Phase3 {...props} routes={r.routes3} />}
          />
          <Route
            path="/phase2"
            render={(props) => <Phase2 {...props} routes={r.routes2} />}
          />
          <Route
            path="/phase1"
            render={(props) => <Main {...props} routes={r.routes} />}
          />
          <Redirect from="/" to="/phase1" />
        </Switch>
      </ScrollPos>
    </div>
  );
}

export default App;
