import React from "react";
import { Link } from "react-router-dom";
import Modal from "../../components/Modal";
import Select from "../../components/Select";
import UploadFile from "../../components/UploadFile";
import { useTranslation } from "react-i18next";

export default function Reject() {
  const [modalIsOpen, setIsOpen] = React.useState(true);
  const { t } = useTranslation();

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="">
      <Modal
        closeModal={closeModal}
        openModal={openModal}
        modalIsOpen={modalIsOpen}
        title={t("phase4.reject")}
      >
        <div className="new-account-form message">
          <div className="message-box">
            <img
              src={`${process.env.PUBLIC_URL}/img/error.svg`}
              alt="message"
            />
            <p>{t("phase4.reject_message")}</p>
            <Link to="/phase4/success" className="btn btn--green w-100">
              {t("phase4.btn_agree")}
            </Link>
          </div>
        </div>
      </Modal>
      <div className="row">
        <div className="comp-details big" onClick={openModal}>
          <div className="comp-details__body">
            <div className="comp-details__body--data">
              <div className="comp-details__body--img"></div>
              <div className="comp-details__body--text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
