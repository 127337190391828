import React from "react";
import { useTranslation } from "react-i18next";
import i18Next from "i18next";

import "react-datetime/css/react-datetime.css";
import "moment/locale/ar";
import Datetime from "react-datetime";

export default function DatePicker() {
  const { t } = useTranslation();

  return <MyDTPicker placeholder={t("placeholders.date")} />;
}

class MyDTPicker extends React.Component {
  render() {
    let inputProps = {
      disabled: false,
    };
    return (
      <Datetime
        timeFormat={false}
        locale={i18Next.languages[0]}
        inputProps={inputProps}
        dateFormat="YYYY/MM/DD"
        renderInput={this.renderInput}
      />
    );
  }
  renderInput = (props, openCalendar, closeCalendar) => {
    function clear() {
      props.onChange({ target: { value: "" } });
    }
    return (
      <div>
        <input
          {...props}
          type="text"
          onClick={openCalendar}
          className={`form__input circle has-icon`}
          placeholder={this.props.placeholder}
        />
        <img
          onClick={openCalendar}
          src={`${process.env.PUBLIC_URL}/img/date.svg`}
          alt="cobon back"
          className="form__icon cursor-pointer"
        />
       
      </div>
    );
  };
}
