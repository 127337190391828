import React from "react";
import { useTranslation } from "react-i18next";



export default function Competition() {
  const {t} = useTranslation()
  return (
    <div className="competition">
        
      <div className="row">
        <div className="comp-details big">
          <div className="comp-details__body">
            <div className="comp-details__body--data">
            <div className="comp-details__body--img">

              <img src={`${process.env.PUBLIC_URL}/img/sympol.svg`} alt=""/>
                  
              </div>
              <div className="comp-details__body--text">
              <p>
                  {t('phase3.notify.comp_name')}: <strong>{t('phase3.notify.comp_name_data')}</strong>
                </p>
                <p>
                  {t('phase3.notify.comp_date')}: <strong>28/1/2021</strong>
                </p>
                <p>
                  {t('phase3.notify.comp_end')}: <strong>28/1/2021</strong>
                </p>
                <p>{t('phase3.notify.desc_title')}: </p>
                <p className="long">
                  <strong className="">
                  {t('phase3.notify.desc')}
                  </strong>
                </p>
                <p> {t('phase3.notify.barcode')}: </p>
                <input
                  type={"text"}
                  className={`form__input  circle`}
                  placeholder= {t('placeholders.code-input')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
