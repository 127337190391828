import React from "react";
import { useTranslation } from "react-i18next";

export default function Result() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="row">
        <div className="flex-between withdraws__card titled">
          <div className="number flex-center">
            <img
              className="hidden-desktop"
              src={`${process.env.PUBLIC_URL}/img/midal2.svg`}
              alt=""
            />
            <img
              className="hidden-mobile"
              src={`${process.env.PUBLIC_URL}/img/midal.svg`}
              alt=""
            />
          </div>
          <div className="content">
            <h1>{t("phase5.whithdraw.name")}</h1>
            <p> {t("phase5.whithdraw.title")} 1 2020/02/16</p>
          </div>
        </div>
        {[0, 0, 0, 0, 0, 0].map((e, i) => (
          <div className="flex-between withdraws__card sub">
            <div className="number flex-center">{i + 1}</div>
            <div className="content">
              <h1>{t("phase5.whithdraw.title2")}</h1>
              <p>Ref-22704</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
