import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import Navbar from "./Navbar";

export default withRouter(function CustomHero({ routes, title, location }) {
  const [brand, setbrand] = React.useState(null);
  const { t } = useTranslation();
const onclick=()=>{
    window.scrollTo({
        left: 0,
        top: 300,
        behavior: "smooth"
      });
}
  let makeBrand = () => null;
  React.useEffect(() => {
    routes.map((prop) => {
      // if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
      //   setbrand(i18next.languages[0]==='en'?prop.name : prop.rtlName)
      // }
      if (location.pathname === prop.layout + prop.path) {
        setbrand(i18next.languages[0] === "en" ? prop.name : prop.rtlName);
      }
    });
  });
  
  return (
    <div className="big-hero">
      <div className="row">
        <div className="big-hero__content">
          <div className="big-hero__right">
            <h1>{t("home.title1")}</h1>
            <h1>
              {t("home.title2")} <span> {t("home.title2_sub")}</span>
            </h1>
            <p>{t("footer.text")}</p>
            <h6>{t("home.download")}</h6>
            <div className="footer">
              <div className="app__btns start">
                <a className="btn btn--white no-hover has-long-radius middle pd-0">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/google_paly.svg`}
                    alt="download ecobon"
                  />
                </a>
                <a className="btn btn--white no-hover has-long-radius middle  pd-0">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/app_store.svg`}
                    alt="download ecobon"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="big-hero__left">
            <img src={`${process.env.PUBLIC_URL}/img/hero.svg`} alt="hero" />
          </div>
        </div>
      </div>
    <div
    onClick={onclick}
    className="scroll-btn">
        <span></span>
    </div>
    </div>
  );
});
