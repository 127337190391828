import React from "react";
import { Link } from "react-router-dom";
import Select from "../../components/Select";
import { useTranslation } from "react-i18next";
import EmailInput from "../../components/EmailInput";

export default function Rigester() {
  const [text, setText] = React.useState("");
  const { t } = useTranslation();

  const options = [
    { value: "1", label: "فرغ" },
    { value: "2", label: "السحب الثاني" },
    { value: "3", label: "السحب الثالث" },
    { value: "4", label: "السحب الرابع" },

    { value: "5", label: "السحب الخامس" },
  ];

  return (
    <div className="phone-form">
      <h1 className="auth__heading">{t("auth.heading")}</h1>
      <h1 className="auth__title">{t("auth.create")}</h1>
      <h3 className="auth__subtitle">{t("auth.new_account")}</h3>

      <div className="auth__box">
        <div className="form__group ">
          <div className="row row--no-padding">
            <div className="col-1-of-2 col-padding-small pos-rel">
              <input
                value={text}
                onChange={(e) => setText(e.target.value)}
                type="text"
                className={`form__input  circle `}
                placeholder={t("placeholders.company-name")}
              />
            </div>
            <div className="col-1-of-2 col-padding-small pos-rel">
              <input
                type="text"
                className={`form__input  circle `}
                placeholder={t("placeholders.company-name-ar")}
              />
            </div>
          </div>
        </div>
        <div className="form__group ">
          <div className="row row--no-padding">
            <div className="pos-rel">
              <Select
                className="filter-select in-form "
                classNamePrefix="select"
                onChange={() => {}}
                placeholder={t("placeholders.type")}
                name="type"
                options={options}
              />
            </div>
          </div>
        </div>

        <div className="form__group ">
          <div className="row row--no-padding">
            <div className="col-1-of-2 col-padding-small pos-rel">
              <Select
                className="filter-select in-form"
                classNamePrefix="select"
                onChange={() => {}}
                placeholder={t("placeholders.country")}
                name="country"
                options={options}
              />
            </div>
            <div className="col-1-of-2 col-padding-small pos-rel">
              <Select
                className="filter-select in-form"
                classNamePrefix="select"
                onChange={() => {}}
                placeholder={t("placeholders.city")}
                name="city"
                options={options}
              />
            </div>
          </div>
        </div>

        <div className="form__group ">
          <div className="row row--no-padding">
            <div className="col-1-of-2 col-padding-small pos-rel">
              <input
                type={"text"}
                className={`form__input  circle `}
                placeholder={t("placeholders.trade_member")}
              />
            </div>
            <div className="col-1-of-2 col-padding-small pos-rel">
              <input
                type={"text"}
                className={`form__input  circle `}
                placeholder={t("placeholders.trade_id")}
              />
            </div>
          </div>
        </div>
        <div className="form__group big-btm">
          <div className="row row--no-padding">
            <div className="col-1-of-2 col-padding-small pos-rel">
              <input
                type={"text"}
                className={`form__input  circle `}
                placeholder={t("placeholders.tele")}
              />
            </div>
            <div className="col-1-of-2 col-padding-small pos-rel">
              <EmailInput placeholder={t("placeholders.email")} />
            </div>
          </div>
        </div>

        <div className="form__group ">
          <Link
            to="/phase3"
            className={` btn bold custom-padding ${
              text.length < 1 ? "btn--disabled" : "btn--green"
            } w-100`}
          >
            {t("placeholders.next")}{" "}
          </Link>
        </div>
      </div>
    </div>
  );
}
