import React from "react";
import { useTranslation } from "react-i18next";


export default function Table({ empty ,columns,data}) {
 const {t} = useTranslation()
  return (
    <table className={`rwd-table ${empty ? "empty" : ""}`}>
      <tr className="table-head">
        {columns.map((e, i) => (
          <th
            key={i}
            className={`${i === 0 ? " right-curve " : ""} ${
              i === columns.length - 1 ? "left-curve" : ""
            }`}
          >
            {e.customName || e.name}
          </th>
        ))}
      </tr>
      {/* <tr>
        <td data-th="#">1</td>
        <td data-th="اسم الفائز">ABDALGHANE MASOUDI</td>
        <td data-th="مكان السحب">--------------</td>
        <td data-th="اسم الجائزة">كاش</td>
        <td data-th="تاريخ السحب">28/1/2021</td>
      </tr> */}
      {
        data.map((d,i)=>(
          <tr key={i} className={`${i%2!=0?'colored':''} `}>
            {columns.map((e,ind)=>(
          <td key={e.name+ind+i} data-th={e.name}>{d[e.name]}</td>

            ))}
         
        </tr>
        ))
      }

      {empty && (
        <div className="empty-content">
          <div className="img flex-center">
            <img
              src={`${process.env.PUBLIC_URL}/img/empty.svg`}
              alt="close filters"
            />
          </div>
          <p>{t('winners.empty')}</p>
        </div>
      )}
    </table>
  );
}
