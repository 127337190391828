import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Challenges() {
  const { t } = useTranslation();
  const card = (
    <div className="challenges__card">
      <div className="challenges__card--img">
        <img src={`${process.env.PUBLIC_URL}/img/sympol.svg`} alt="card" />
      </div>
      <div className="challenges__card--content">
        <h1>{t("phase5.card.title")}</h1>
        <h4 className="price">
          660 <span>{t("phase5.card.price")}</span>
        </h4>
      </div>
    </div>
  );
  return (
    <div className="challenges challenges-mobile">
      <div className="row">
        <div className="challenges__row ">
          {[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((e, i) => (
            <Link
              to="/phase5/competitions/single"
              key={i}
              className="challenges__row--col "
            >
              {card}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
