import React from "react";

export default function PhoneInput({ icon, placeholder }) {
  const [text, setText] = React.useState("");

  const onchange = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      setText(e.target.value);
    }
  };
  return (
    <>
      <input
        onChange={onchange}
        value={text}
        type="text"
        className={`form__input ${icon ? "has-icon" : ""} circle ${
          text.length > 9 ? "error" : text.length === 9 ? "success" : ""
        }`}
        placeholder={placeholder}
      />
      {icon && (
        <img
          src={`${process.env.PUBLIC_URL}/img/phone.svg`}
          alt="cobon back"
          className="form__icon"
        />
      )}

      <label className="form__label">رقم الهاتف</label>
    </>
  );
}
