import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const languageMap = {
  en: { label: "English", dir: "ltr", active: false, english: true },
  ar: { label: "العربية", dir: "rtl", active: true, english: false },
};

export default withRouter(function Navbar(props) {
  const [showNav, setshowNav] = React.useState(false);
  const [scrollable, setscrollable] = React.useState(false);
  /**
   * * setup functions for language change and detection
   */
  const selected = localStorage.getItem("i18nextLng") || "ar";
  const { t } = useTranslation();
  React.useEffect(() => {
    languageMap[selected].english
      ? document.body.classList.add("english")
      : document.body.classList.remove("english");
  }, [selected]);

  const onLanguageElementClick = (item) => {
    i18next.changeLanguage(item);
    setshowNav(false);
  };

  // set navbar scrolled class when scrol a liitle from top
  const scrollAction = () => {
    const isTop = window.scrollY > 10;
    const nav1 = document.getElementById("desktop-nav");
    const nav2 = document.getElementById("mobile-nav");
    if (isTop) {
      nav1?.classList.add("scrolled");
      setscrollable(true);
    } else {
      nav1?.classList.remove("scrolled");
      setscrollable(false);
    }
  };
  React.useEffect(() => {
    window.addEventListener("scroll", scrollAction);
    return () => {
      window.removeEventListener("scroll", scrollAction);
    };
  }, []);



  //This the main menu "phase1 layout menu"
  const renderMenu = (
    <ul className={`${showNav ? "show" : ""}`}>
      <li
        onClick={() => setshowNav(false)}
        className={` ${
          props.location.pathname === "/phase1/home" ? "active" : ""
        }`}
      >
        <Link to="/phase1/home">{t("navbar:home")}</Link>
        <div className="title-line">
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>
      </li>
      <li
        onClick={() => setshowNav(false)}
        className={` ${
          props.location.pathname === "/phase1/services" ? "active" : ""
        }`}
      >
        <Link to="/phase1/services">{t("navbar:services")}</Link>
        <div className="title-line">
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>
      </li>

      <li
        onClick={() => setshowNav(false)}
        className={` ${
          props.location.pathname === "/phase1/winners" ? "active" : ""
        }`}
      >
        <Link to="/phase1/winners">{t("navbar:winners")}</Link>
        <div className="title-line">
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>
      </li>
      <li
        onClick={() => setshowNav(false)}
        className={` ${
          props.location.pathname === "/phase1/about" ? "active" : ""
        }`}
      >
        <Link to="/phase1/about">{t("navbar:about")}</Link>
        <div className="title-line">
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>
      </li>
      <li
        onClick={() => setshowNav(false)}
        className={` ${
          props.location.pathname === "/phase1/contact" ? "active" : ""
        }`}
      >
        <Link to="/phase1/contact">{t("navbar:contact")}</Link>
        <div className="title-line">
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>
      </li>
      <li
        className={` ${
          props.location.pathname === "/phase1/english" ? "active" : ""
        }`}
      >
        <a
          onClick={() =>
            onLanguageElementClick(languageMap[selected].english ? "ar" : "en")
          }
        >
          {languageMap[selected].english ? "العربية" : "English"}
        </a>
        <div className="title-line">
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>
      </li>
      <li className={`btns `}>
        <Link
          onClick={() => setshowNav(false)}
          to="/phase2"
          className="btn btn--yellow has-md-radius"
        >
          {t("navbar:login")}
        </Link>
        <Link
          onClick={() => setshowNav(false)}
          className="btn btn--main-border has-md-radius"
          to="/phase2/loginCompany"
        >
          {t("navbar:works")}
        </Link>
      </li>
      <li className="hidden-mobile">
        <Link
          to="/phase2"
          className={`btn has-md-radius ${
            scrollable ? "btn--yellow" : "btn--white"
          } `}
        >
          {t("navbar:login")}
        </Link>
      </li>
      <li className="hidden-mobile">
        <Link
          className={`btn has-md-radius ${
            scrollable ? "btn--main-border" : "btn--white-border"
          } `}
          to="/phase2/loginCompany"
        >
          {t("navbar:works")}
        </Link>
      </li>
    </ul>
  );
  //phase3 menu
  const renderPhase3Menu = (
    <ul className={`${showNav ? "show" : ""}`}>
      <li
        onClick={() => setshowNav(false)}
        className={` ${
          props.location.pathname === "/phase1/home" ? "active" : ""
        }`}
      >
        <Link to="/phase1/home">{t("navbar:home")}</Link>
        <div className="title-line">
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>
      </li>

      <li
        onClick={() => setshowNav(false)}
        className={` ${
          props.location.pathname === "/phase1/winners" ? "active" : ""
        }`}
      >
        <Link to="/phase1/winners">{t("navbar:winners")}</Link>
        <div className="title-line">
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>
      </li>
      <li
        onClick={() => setshowNav(false)}
        className={` ${
          props.location.pathname === "/phase1/about" ? "active" : ""
        }`}
      >
        <Link to="/phase1/about">{t("navbar:about")}</Link>
        <div className="title-line">
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>
      </li>
      <li
        onClick={() => setshowNav(false)}
        className={` ${
          props.location.pathname === "/phase1/contact" ? "active" : ""
        }`}
      >
        <Link to="/phase1/contact">{t("navbar:contact")}</Link>
        <div className="title-line">
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>
      </li>
      <li
        onClick={() => setshowNav(false)}
        className={` ${
          props.location.pathname === "/phase3/participations" ? "active" : ""
        }`}
      >
        <Link to="/phase3/participations">{t("navbar:my_data")}</Link>
        <div className="title-line">
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>
      </li>
      <li
        className={` ${
          props.location.pathname === "/phase1/english" ? "active" : ""
        }`}
      >
        <a
          onClick={() =>
            onLanguageElementClick(languageMap[selected].english ? "ar" : "en")
          }
        >
          {languageMap[selected].english ? "العربية" : "English"}
        </a>
        <div className="title-line">
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>
      </li>

      <li className={`btns `}>
        <Link
          onClick={() => setshowNav(false)}
          className="btn btn--circle btn--circle-blue "
          to="/phase3/notifies"
        >
          <span>4</span>
          <img
            className="normal"
            src={`${process.env.PUBLIC_URL}/img/notifications.svg`}
            alt="notifications"
          />
          <img
            className="onHover"
            src={`${process.env.PUBLIC_URL}/img/notifications_2.svg`}
            alt="notifications"
          />
        </Link>
        <Link
          onClick={() => setshowNav(false)}
          className="btn btn--dropdown btn--dropdown-blue "
          to="/phase1/services"
        >
          <div className="icon">
            <img
              src={`${process.env.PUBLIC_URL}/img/user_2.svg`}
              alt="user_2"
            />
          </div>
          <span>{t("navbar:client")}</span>
          <div className="arrow">
            <img
              src={`${process.env.PUBLIC_URL}/img/chevron-down_white.svg`}
              alt="chevron-down"
            />
          </div>
        </Link>
      </li>
      <li className="hidden-mobile">
        <Link
          className={`btn btn--circle  
      ${scrollable ? "btn--circle-blue" : ""}
      `}
          to="/phase3/notifies"
        >
          <span>4</span>
          <img
            className="normal"
            src={`${process.env.PUBLIC_URL}/img/notifications.svg`}
            alt="notifications"
          />
          <img
            className="onHover"
            src={`${process.env.PUBLIC_URL}/img/notifications_2.svg`}
            alt="notifications"
          />
        </Link>
      </li>
      <li className="hidden-mobile">
        <Link
          className={`btn btn--dropdown 
       ${scrollable ? "btn--dropdown-blue" : ""}
      `}
          to="/phase1/services"
        >
          <div className="icon">
            <img
              src={`${process.env.PUBLIC_URL}/img/user_2.svg`}
              alt="user_2"
            />
          </div>
          <span>{t("navbar:client")}</span>
          <div className="arrow">
            <img
              src={`${process.env.PUBLIC_URL}/img/${
                scrollable ? "chevron-down_white" : "chevron-down"
              }.svg`}
              alt="chevron-down"
            />
          </div>
        </Link>
      </li>
    </ul>
  );

  //phase 4 menu
  const renderPhase4Menu = (
    <ul className={`${showNav ? "show" : ""}`}>
      <li onClick={() => setshowNav(false)} className={``}>
        <a
          className="btn btn-text hovered"
          onClick={() =>
            onLanguageElementClick(languageMap[selected].english ? "ar" : "en")
          }
        >
          {languageMap[selected].english ? "اللغة العربية" : "English"}
        </a>
      </li>

      <li>
        <Link
          onClick={() => setshowNav(false)}
          to="/phase4/new-account"
          className="btn btn--yellow has-md-radius"
        >
          {t("navbar:sign_up")}
        </Link>
      </li>
      <li className="">
        <Link
          onClick={() => setshowNav(false)}
          to="/phase4/login-account"
          className="btn btn--yellow has-md-radius"
        >
          {t("navbar:sign")}
        </Link>
      </li>
    </ul>
  );
  return (
    <>
      <header
        id="desktop-nav"
        className={`nav ${props.phase4 ? "custom-nav" : ""}`}
      >
        <div
          className={`overlay ${showNav ? "show" : ""}`}
          onClick={() => setshowNav(!showNav)}
        ></div>
        <div className={`mobile  ${showNav ? "show" : ""}`}>
          <div className="row">
            <nav className={`header  `}>
              <div className="action-btns">
                <div className="menu" onClick={() => setshowNav(!showNav)}>
                  <img
                    src={`${process.env.PUBLIC_URL}/img/close2.svg`}
                    alt="Ecopon logo"
                    className={``}
                  />
                </div>

                <Link to="/" className="logo">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/logo_1.svg`}
                    alt="Ecopon logo"
                    className={``}
                  />
                </Link>
              </div>
              {props.phase3
                ? renderPhase3Menu
                : props.phase4
                ? renderPhase4Menu
                : renderMenu}
            </nav>
          </div>
        </div>

        <div className="row">
          <nav className="header ">
            <div className="menu" onClick={() => setshowNav(!showNav)}>
              <img
                src={`${process.env.PUBLIC_URL}/img/menu.svg`}
                alt="Ecopon logo"
                className={``}
              />
            </div>

            <Link to="/" className="logo">
              <img
                src={`${process.env.PUBLIC_URL}/img/logo_1.svg`}
                alt="Ecopon logo"
                className={`main-logo`}
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/logo_2.svg`}
                alt="Ecopon logo"
                className={`white-logo`}
              />
            </Link>
            {props.phase3
              ? renderPhase3Menu
              : props.phase4
              ? renderPhase4Menu
              : renderMenu}
          </nav>
        </div>
      </header>
    </>
  );
});
