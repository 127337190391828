import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Retsurants() {
  const { t } = useTranslation();
  const card = (
    <div className="challenges__card details">
      <div className="challenges__card--img">
        <img src={`${process.env.PUBLIC_URL}/img/sympol.svg`} alt="card" />
      </div>
      <div className="challenges__card--content details">
        <h1>{t("phase5.card.title2")}</h1>
        <h4 className="price">
          <span className="main">
            400 <span>{t("phase5.card.coin")} </span>
          </span>
          <span className="text">{t("phase5.card.instead")} </span>
          <span className="red">
            500 <span>{t("phase5.card.coin")}</span>
          </span>
        </h4>
      </div>
    </div>
  );
  return (
    <div className="challenges">
      <div className="row">
        <div className="challenges__row ">
          {[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((e, i) => (
            <Link
              to="/phase5/copons/resturants/details"
              key={i}
              className="challenges__row--col "
            >
              {card}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
