import React from "react";
import { Link, withRouter } from "react-router-dom";

import { Route, Switch, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Slider from "react-slick";

const languageMap = {
  en: { label: "English", dir: "ltr", active: false, english: true },
  ar: { label: "العربية", dir: "rtl", active: true, english: false },
};
export default withRouter(function Phase2({ routes, children, location }) {

  /**
   * *translation setup and intialization in phase2 routes and UIs
   */
  const selected = localStorage.getItem("i18nextLng") || "ar";
  const { t } = useTranslation();
  React.useEffect(() => {
    languageMap[selected].english
      ? document.body.classList.add("english")
      : document.body.classList.remove("english");
  }, [selected]);
  const onLanguageElementClick = (item) => {
    i18next.changeLanguage(item);
  };


  const [height, setHeight] = React.useState(50);
  const ref = React.useRef(null);

  React.useEffect(() => {
    //make the hight of the mobile blue background dynamic to the content change, based on XD design
    const h = ref.current.clientHeight - (26 / 100) * ref.current.clientHeight;
    setHeight(h);
  });
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: true,
    rtl: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div
      className="auth"
      style={{
        background: `url(${process.env.PUBLIC_URL}/img/bg.png)  no-repeat `,

        minHeight: "100vh",
        minWidth: "100%",
        // height: "100vh",
      }}
    >
      <div
        style={{
          background: `url(${process.env.PUBLIC_URL}/img/bg.png)  no-repeat `,

          height: `${
            location.pathname == "/phase2/code"
              ? height + 7
              : height > 350
              ? 300
              : height
          }px`,
        }}
        className="auth__mobile-bg"
      ></div>
      <div className="auth__right">
        <div className="auth__content">
          <div className="auth__nav">
            <img
              src={`${process.env.PUBLIC_URL}/img/logo_1.svg`}
              alt="copon logo"
              className="main-logo"
            />
            <img
              src={`${process.env.PUBLIC_URL}/img/logo_2.svg`}
              alt="copon logo"
              className="white-logo"
            />
            {(location.pathname == "/phase2/loginCompany" ||
              location.pathname == "/phase2/registerCompany") && (
              <div>
                {" "}
                <h1 className="auth__heading nav">{t("auth.heading")}</h1>
              </div>
            )}
            <ul>
              <li className="desktop-btn">
                <a
                  onClick={() => onLanguageElementClick("ar")}
                  className={`btn ${
                    i18next.languages[0] === "en"
                      ? " btn-link "
                      : " btn--yellow small-padding"
                  }`}
                >
                  العربية
                </a>
              </li>

              <li className="desktop-btn">
                <a
                  onClick={() => onLanguageElementClick("en")}
                  className={`btn ${
                    i18next.languages[0] === "ar"
                      ? " btn-link "
                      : " btn--yellow small-padding"
                  }`}
                >
                  English
                </a>
              </li>
              <li className="mobile-btn">
                <a
                  onClick={() => onLanguageElementClick("ar")}
                  className={`btn ${
                    i18next.languages[0] === "en"
                      ? " btn-link btn-link--white "
                      : " btn--white small-padding"
                  }`}
                >
                  العربية
                </a>
              </li>

              <li className="mobile-btn">
                <a
                  onClick={() => onLanguageElementClick("en")}
                  className={`btn ${
                    i18next.languages[0] === "ar"
                      ? " btn-link btn-link--white "
                      : " btn--white small-padding"
                  }`}
                >
                  English
                </a>
              </li>
            </ul>
          </div>
          <div ref={ref} className="auth__body">
            <Switch>
              {routes.map((prop, key) => {
                return (
                  <Route
                    path={prop.layout + prop.path}
                    render={(props) => (
                      <prop.component {...props} title={`${prop.rtlName}`} />
                    )}
                    key={key}
                  />
                );
              })}
              <Redirect from="/phase2" to="/phase2/auth" />
            </Switch>
            <div className="auth__body--btn">
              <img
                src={`${process.env.PUBLIC_URL}/img/chevron-left_2.svg`}
                alt="cobon back"
              />

              <Link to="/" className="btn btn--normal">
                {t("auth.back")}
              </Link>
            </div>
          </div>
          <div className="auth__footer">
            <p>{t("auth.footer")} </p>
            <div className="auth__footer--btns">
              <Link to="/phase1" className="btn btn-text hovered btn--small">
                {t("auth.terms")}
              </Link>
              <p> {t("auth.and")}</p>
              <Link to="/phase1" className="btn btn-text hovered btn--small">
                {t("footer.policy_link")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="auth__left">
        <Slider {...settings}>
          <div className="slide-wrapper">
            <img src={`${process.env.PUBLIC_URL}/img/white_img.png`} alt="" />
          </div>
          <div className="slide-wrapper">
            <img src={`${process.env.PUBLIC_URL}/img/white_img.png`} alt="" />
          </div>
          <div className="slide-wrapper">
            <img src={`${process.env.PUBLIC_URL}/img/white_img.png`} alt="" />
          </div>
        </Slider>
        {/* <div className="dots">
          <span className="dot"></span>
          <span className="dot active"></span>
          <span className="dot"></span>
        </div> */}
      </div>
    </div>
  );
});
