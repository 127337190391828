import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import DatePicker from "../../components/DatePicker";
import PhoneInput from "../../components/PhoneInput";
import Select from "../../components/Select";
import EmailInput from "../../components/EmailInput";

export default function MyData() {
  const [text, setText] = React.useState("");
  const options2 = [
    { value: "1", label: "فرغ" },
    { value: "2", label: "السحب الثاني" },
    { value: "3", label: "السحب الثالث" },
    { value: "4", label: "السحب الرابع" },

    { value: "5", label: "السحب الخامس" },
  ];
  const { t } = useTranslation();
  return (
    <div className="my-data">
      <div className="row">
        <div className="goBack-btn right top hidden-mobile flex">
          <img
            src={`${process.env.PUBLIC_URL}/img/chevron-left_blue.svg`}
            alt="cobon back"
          />

          <Link to="/" className="btn btn--normal btn--normal-blue ">
            {t("auth.back")}
          </Link>
        </div>

        <div className="my-data__box">
          <h1 className="box-title">{t("phase3.personal")}</h1>
          <form className="my-data__form font-0">
            <div className="form__group md-btm2">
              <div className="row row--no-padding">
                <div className="col-1-of-2 md-btm col-padding-small pos-rel">
                  <input
                    type="text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    className={`form__input   circle `}
                    placeholder={t("placeholders.name")}
                  />
                </div>
                <div className="col-1-of-2 col-padding-small pos-rel font-intial">
                  <DatePicker />
                </div>
              </div>
            </div>
            <div className="form__group md-btm2">
              <div className="row row--no-padding">
                <div className="col-1-of-2 md-btm col-padding-small pos-rel">
                  <input
                    type="text"
                    className={`form__input   circle `}
                    placeholder={t("placeholders.address1")}
                  />
                </div>
                <div className="col-1-of-2 col-padding-small pos-rel">
                  <input
                    type="text"
                    className={`form__input   circle `}
                    placeholder={t("placeholders.address2")}
                  />
                </div>
              </div>
            </div>

            <div className="form__group md-btm2">
              <div className="row row--no-padding">
                <div className="col-1-of-2 md-btm col-padding-small pos-rel">
                  <PhoneInput placeholder={t("placeholders.phone2")} />
                </div>
                <div className="col-1-of-2 col-padding-small pos-rel">
                  <input
                    type="text"
                    className={`form__input   circle `}
                    placeholder={t("placeholders.choose")}
                  />
                </div>
              </div>
            </div>

            <div className="form__group md-btm2">
              <div className="row row--no-padding">
                <div className="col-1-of-2 md-btm col-padding-small pos-rel">
                  <Select
                    className="filter-select in-form "
                    classNamePrefix="select"
                    onChange={() => {}}
                    placeholder={t("placeholders.sex")}
                    name="sex"
                    options={options2}
                  />
                </div>
                <div className="col-1-of-2 col-padding-small pos-rel">
                  <input
                    type="text"
                    className={`form__input   circle `}
                    placeholder={t("placeholders.choose")}
                  />
                </div>
              </div>
            </div>

            <div className="form__group md-btm2 display-in-bl">
              <div className="row row--no-padding">
                <div className="col-1-of-2 md-btm col-padding-small pos-rel">
                  <Select
                    className="filter-select in-form "
                    classNamePrefix="select"
                    onChange={() => {}}
                    placeholder={t("placeholders.country")}
                    name="country"
                    options={options2}
                  />
                </div>
                <div className="col-1-of-2 col-padding-small pos-rel">
                  <Select
                    className="filter-select in-form "
                    classNamePrefix="select"
                    onChange={() => {}}
                    placeholder={t("placeholders.city")}
                    name="city"
                    options={options2}
                  />
                </div>
              </div>
            </div>

            <div className="form__group mg-top display-in-bl">
              <Link
                to="/phase3/password"
                className={`btn  
             ${text.length < 1 ? "btn--disabled" : "btn--green"}
          w-100`}
              >
                {t("placeholders.log")}{" "}
              </Link>
            </div>
          </form>
        </div>

        <div className="my-data__box mg-top-bigger">
          <h1 className="box-title">{t("phase3.request")}</h1>
          <form className="my-data__form font-0">
            <div className="form__group md-btm2">
              <div className="row row--no-padding">
                <div className="col-1-of-2 md-btm col-padding-small pos-rel">
                  <Select
                    className="filter-select in-form "
                    classNamePrefix="select"
                    onChange={() => {}}
                    placeholder={t("placeholders.card_type")}
                    name="id"
                    options={options2}
                  />
                </div>
                <div className="col-1-of-2 col-padding-small pos-rel">
                  <PhoneInput placeholder={t("placeholders.card_num")} />
                </div>
              </div>
            </div>
            <div className="form__group md-btm2">
              <div className="row row--no-padding">
                <div className="col-1-of-2 md-btm col-padding-small pos-rel">
                  <EmailInput placeholder={t("placeholders.email")} />
                </div>
                <div className="col-1-of-2 col-padding-small pos-rel">
                  <PhoneInput placeholder={t("placeholders.tele")} />
                </div>
              </div>
            </div>

            <div className="form__group mg-top display-in-bl">
              <Link
                to="/phase3/password"
                className={`btn  
             ${text.length < 1 ? "btn--disabled" : "btn--green"}
          w-100`}
              >
                {t("placeholders.request_admin")}{" "}
              </Link>
            </div>
          </form>
        </div>

        <div className="goBack-btn center bottom">
          <img
            src={`${process.env.PUBLIC_URL}/img/chevron-left_2.svg`}
            alt="cobon back"
          />

          <Link to="/" className="btn btn--normal  ">
            {t("auth.back")}
          </Link>
        </div>
      </div>
    </div>
  );
}
