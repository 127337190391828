import React from "react";

import { CSSTransition } from "react-transition-group";
import Modal from "react-modal";

export default class ModalC extends React.Component {
  state = {
    isOpen: false,
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  render() {
    const modalStyles = {
      overlay: {
        backgroundColor: "#00224728",
      },
      content: {
        width: "90%",
        //margin: '2rem',
        maxWidth: "492px",
        maxHeight: "100%",
        //    marginBottom:'3rem',
        //inset:0,
        overflow: "visible",
        padding: "1.2rem 0 1rem",
        left: "50%",
        right: "auto",
        bottom: "auto",
        top: "13%",
        borderRadius: "8px",
        border: "1px solid transparent",
        // transform: "translateX(-50%)",
      },
    };
    return (
      <div>
        {/* <button onClick={this.toggleModal}>
          Open Modal
        </button> */}
        <CSSTransition
          in={this.props.modalIsOpen}
          timeout={300}
          classNames="dialog"
        >
          <Modal
            closeTimeoutMS={500}
            onRequestClose={this.props.closeModal}
            isOpen={this.props.modalIsOpen}
            style={modalStyles}
            appElement={document.getElementById("root")}
          >
            <div className="modal-content__header">
              <h3>{this.props.title}</h3>
              <a onClick={this.props.closeModal}>
                <img
                  src={`${process.env.PUBLIC_URL}/img/close2.svg`}
                  alt="clsoe content"
                  className={``}
                />
              </a>
            </div>
            <div className="modal-content-line"></div>
            {/* <Scrollbars style={{  minHeight: 300 }}
          renderView={props => (<div {...props} style={{ ...props.style, marginLeft: props.style.marginRight, marginRight: 0, }} /> )}
          > */}

            <div
              className="modal-content_body"
              style={{ maxHeight: `${(70 / 100) * window.innerHeight}px` }}
            >
              {this.props.children}
            </div>
            {/* </Scrollbars> */}
          </Modal>
        </CSSTransition>
      </div>
    );
  }
}
