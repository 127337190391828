import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Modal from "../../components/Modal";

export default function MyData() {
  const dummyList = [1, 1, 1, 11, 1, 1, 1, 1, 1, 1, 1];
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [text, setText] = React.useState("");
  const { t } = useTranslation();
  const onchange = (e) => {
    setText(e.target.value);
  };
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div className="notifies">
      <Modal
        closeModal={closeModal}
        openModal={openModal}
        modalIsOpen={modalIsOpen}
        title={t("phase3.notify.comp")}
      >
        <div className="comp-details">
          <div className="comp-details__body">
            <div className="comp-details__body--data">
              <div className="comp-details__body--img">
                <img src={`${process.env.PUBLIC_URL}/img/sympol.svg`} alt="" />
              </div>
              <div className="comp-details__body--text">
                <p>
                  {t("phase3.notify.comp_name")}:{" "}
                  <strong>{t("phase3.notify.comp_name_data")}</strong>
                </p>
                <p>
                  {t("phase3.notify.comp_date")}: <strong>28/1/2021</strong>
                </p>
                <p>
                  {t("phase3.notify.comp_end")}: <strong>28/1/2021</strong>
                </p>
                <p>{t("phase3.notify.barcode")}: </p>
                <input
                  onChange={onchange}
                  value={text}
                  type={"text"}
                  className={`form__input  circle`}
                  placeholder={t("placeholders.code-input")}
                />
              </div>
            </div>
            {text.length > 6 && (
              <p className="mg-top-middle comp-details__error">
                {t("phase3.notify.error")}
              </p>
            )}
            <div className="comp-details__body--btn">
              <Link
                to="/phase2/loginCompany"
                className={`mg-top-middle  btn bold custom-padding ${
                  text.length < 1 || text.length > 6
                    ? "btn--disabled"
                    : "btn--green"
                } w-100`}
              >
                {t("placeholders.send")}{" "}
              </Link>
            </div>
          </div>
          <div className="comp-details__footer">
            <p>{t("auth.footer")} </p>
            <div className="comp-details__footer--btns">
              <Link to="/phase1" className="btn btn-text hovered btn--small">
                {t("auth.terms")}
              </Link>
              <p> {t("auth.and")}</p>
              <Link to="/phase1" className="btn btn-text hovered btn--small">
                {t("footer.policy_link")}
              </Link>
            </div>
          </div>
        </div>
      </Modal>
      <div className="row">
        <div className="goBack-btn right top hidden-mobile flex">
          <img
            src={`${process.env.PUBLIC_URL}/img/chevron-left_blue.svg`}
            alt="cobon back"
          />

          <Link to="/" className="btn btn--normal btn--normal-blue ">
            {t("auth.back")}
          </Link>
        </div>
        <div className="goBack-btn right top hidden-desktop">
          <img
            src={`${process.env.PUBLIC_URL}/img/chevron-left_white.svg`}
            alt="cobon back"
          />

          <Link to="/" className="btn btn--normal btn--normal-white ">
            {t("auth.back")}
          </Link>
        </div>

        <div className="notifies__box">
          {dummyList.map((l, i) => (
            <div onClick={openModal} className="notification">
              <div className="notification__img">
                <div className="notification__img--box">
                  <span>صورة الباركود</span>
                </div>
                <span className="date">16/02/2021</span>
              </div>
              <div className="notification__text">
                <h1>{t("phase3.notify.title")}</h1>
                <p className="hidden-mobile">{t("phase3.notify.sub")}</p>
                <p className="hidden-desktop">{t("phase3.notify.sub2")}</p>
                <span>16/02/2021</span>
              </div>
              <div className="notification__arrow">
                <img
                  src={`${process.env.PUBLIC_URL}/img/chevron-left.svg`}
                  alt="notify"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
