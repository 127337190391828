import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Modal from "../../components/Modal";

import CodeForm from "../Phase2/CodeForm";


export default function LoginUser3(props) {

  const [modalIsOpen, setIsOpen] = React.useState(true);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  
  const onclick = (e) => {
    props.history.push("/phase4/personal")
  };
 const {t} = useTranslation()
 
  return (
    <div className="competition">
      <Modal
        closeModal={closeModal}
        openModal={openModal}
        modalIsOpen={modalIsOpen}
        title= {t('navbar:sign')}
      >
        <div className="new-account-form">
        <CodeForm 
        small={true}
        clickAction={onclick}
        />
              </div>
      </Modal>
      <div className="row">
        <div className="comp-details big"
        onClick={openModal}
        >
          <div className="comp-details__body">
            <div className="comp-details__body--data">
              <div className="comp-details__body--img"></div>
              <div className="comp-details__body--text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
