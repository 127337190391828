import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PasswordInput from "../../components/PasswordInput";

export default function MyData() {

  const { t } = useTranslation();
  return (
    <div className="password-resign">
      <div className="row">
        <h1 className="password-resign-title">{t("auth.recreate.title")}</h1>
        <h3 className="password-resign-sub">{t("auth.recreate.sub")}</h3>

        <div className="password-resign__box">
          <form className="password-resign__form">
            <div className="form__group ">
              <div className="row row--no-padding">
                <div className=" col-padding-small pos-rel">
                  <PasswordInput
                    placeholder={
                      t("placeholders.old") + " " + t("placeholders.passord")
                    }
                  />
                </div>
              </div>
            </div>

            <div className="form__group ">
              <div className="row row--no-padding">
                <div className=" pos-rel">
                  <PasswordInput
                    placeholder={
                      t("placeholders.new") + " " + t("placeholders.passord")
                    }
                  />
                </div>
              </div>
            </div>

            <div className="form__group big-btm">
              <div className="row row--no-padding">
                <div className=" pos-rel">
                  <PasswordInput
                    placeholder={
                      t("placeholders.new") + " " + t("placeholders.confirm")
                    }
                  />
                </div>
              </div>
            </div>

            <div className="form__group">
              <Link
                to="/phase3/participations"
                className={`btn  
             btn--disabled
          w-100`}
              >
                {t("placeholders.change")}{" "}
              </Link>
            </div>
          </form>
        </div>
        <div className="goBack-btn center bottom">
          <img
            src={`${process.env.PUBLIC_URL}/img/chevron-left_2.svg`}
            alt="cobon back"
          />

          <Link to="/" className="btn btn--normal  ">
            {t("auth.back")}
          </Link>
        </div>
      </div>
    </div>
  );
}
