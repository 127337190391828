import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function SingleChallenge() {
  const { t } = useTranslation();

  return (
    <div className="">
      <div className="row">
        <div className="comp-details big">
          <div className="comp-details__body">
            <div className="comp-details__body--data">
              <div className="comp-details__body--img">
                <img src={`${process.env.PUBLIC_URL}/img/sympol.svg`} alt="" />
              </div>
              <div className="comp-details__body--text">
                <h1>{t("phase3.notify.name")}</h1>
                <p>
                  {t("phase3.notify.comp_name")}:{" "}
                  <strong>{t("phase3.notify.comp_name_data")}</strong>
                </p>
                <p>
                  {t("phase3.notify.comp_date")}: <strong>28/1/2021</strong>
                </p>
                <p>
                  {t("phase3.notify.comp_end")}:{" "}
                  <strong className="red">28/1/2021</strong>
                </p>
                <p>
                  {t("phase3.notify.awards")}:{" "}
                  <strong className="main">200 {t("phase5.card.price")}</strong>
                </p>
                <p>
                  {t("phase3.notify.whithdraw")}: <strong>28/1/2021</strong>
                </p>
                <p>{t("phase3.notify.desc_title")}: </p>
                <p className="long">
                  <strong className="">{t("phase3.notify.desc")}</strong>
                </p>
                <Link
                  to="/phase5/copons"
                  className={` btn middle  btn--green
           
             `}
                >
                  {t("placeholders.subscribe")}{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="withdraws">
          {[0, 0, 0].map((e, i) => (
            <div className="flex-between withdraws__card ">
              <div className="number flex-center">{i + 1}</div>
              <div className="content">
                <h1>
                  {t("phase5.whithdraw.title")} <span>{i + 1}</span>
                </h1>
                <p>2020/02/16</p>
              </div>
              <Link
                to="/phase5/competitions/single/results"
                className="btn btn--yellow has-md-radius"
              >
                {t("phase5.whithdraw.btn")}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
