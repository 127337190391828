import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import Tabs from "../components/Tabs";
import Copons from "./phase5/Copons";

export default function Home() {
  const { t } = useTranslation();

  //swiper settings for copons and competitions slider
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 5,
    centerPadding: "40px",
    arrows: false,
    autoplay: true,

    slidesToScroll: 1,

    centerMode: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1404,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  //swiper settings for testimonial slider

  const settings_test = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    autoplay: true,
    arrows: false,

    slidesToScroll: 1,
    appendDots: (dots) => (
      <div>
        <ul className="custom-dots" style={{ margin: "0px" }}>
          {" "}
          {dots}{" "}
        </ul>
      </div>
    ),
    customPaging: (i) => <div className="custom-dot"></div>,

    swipeToSlide: true,
  };
  //swiper settings for clients slider

  const settings_clients = {
 
    slidesToShow: 11,
    centerMode: true,

    autoplay: true,
    autoplaySpeed: 2000,
      cssEase: "linear",
    arrows: false,
    
    infinite: true,
   
    slidesToScroll: 1,

    speed: 2000,

    responsive: [
      {
        breakpoint: 1404,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
    
  };

  const comp_card = (
    <div className="challenges__card">
      <div className="challenges__card--img big">
        <img src={`${process.env.PUBLIC_URL}/img/sympol.svg`} alt="card" />
      </div>
      <div className="challenges__card--content">
        <h1 className="">{t("phase5.card.title")}</h1>
        <h4 className="price ">
          660 <span>{t("phase5.card.price")}</span>
        </h4>
      </div>
    </div>
  );
  const card_copon = (
    <div className="challenges__card details">
      <div className="challenges__card--img big">
        <img src={`${process.env.PUBLIC_URL}/img/sympol.svg`} alt="card" />
      </div>
      <div className="challenges__card--content details">
        <h1 className="">{t("phase5.card.title2")}</h1>
        <h4 className="price ">
          <span className="main">
            400 <span>{t("phase5.card.coin")} </span>
          </span>
          <span className="text">{t("phase5.card.instead")} </span>
          <span className="red">
            500 <span>{t("phase5.card.coin")}</span>
          </span>
        </h4>
      </div>
    </div>
  );
  const comps_tab = (
    <div>
      <Slider {...settings}>
        {[0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((e, i) => (
          <Link to="/phase5/competitions/single" key={i} className="slide-wrapper slide-padding">
            {comp_card}
          </Link>
        ))}
      </Slider>
    </div>
  );
  const copons_tab = (
    <Slider {...settings}>
      
      {[0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((e, i) => (
        <Link to="/phase5/copons/resturants/details"   key={i} className="slide-wrapper slide-padding">
          {card_copon}
        </Link>
      ))}
    </Slider>
  );
  return (
    <div>
      <div className="home-about">
        <div className="row">
          <div className="flex-center wrapper">
            <img src={`${process.env.PUBLIC_URL}/img/about.svg`} alt="about" />
            <div className="home-about__content">
              <h2 className="has-line">{t("about.header")}</h2>
              <p>{t("footer.text")}</p>
              <p>{t("about.body")}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="home-slider">
        <Tabs
          tabs={[
            { name: t("home.tabs.tab1"), content: comps_tab,linkTo:'/phase5/competitions' },
            { name: t("home.tabs.tab2"), content: copons_tab,linkTo:'/phase5/copons' },
          ]}
        />
      </div>

      <div className="home-why">
        <div className="row">
          <div className="flex-between wrapper">
            <div className="home-why__content">
              <h1 className="has-line">{t("home.why.title")}</h1>
              <p className="sub">{t("home.why.desc")}</p>
              <div className="home-why__list">
                <div className="home-why__list--item">
                  <div className="img">
                    <img
                      src={`${process.env.PUBLIC_URL}/img/enail_con.svg`}
                      alt="why copon"
                    />
                  </div>
                  <div className="text">
                    <h2>{t("home.why.sub1")}</h2>
                    <p>{t("home.why.desc1")}</p>
                  </div>
                </div>
                <div className="home-why__list--item">
                  <div className="img">
                    <img
                      src={`${process.env.PUBLIC_URL}/img/enail_con.svg`}
                      alt="why copon"
                    />
                  </div>
                  <div className="text">
                    <h2>{t("home.why.sub2")}</h2>
                    <p>{t("home.why.desc1")}</p>
                  </div>
                </div>
                <div className="home-why__list--item">
                  <div className="img">
                    <img
                      src={`${process.env.PUBLIC_URL}/img/enail_con.svg`}
                      alt="why copon"
                    />
                  </div>
                  <div className="text">
                    <h2>{t("home.why.sub3")}</h2>
                    <p>{t("home.why.desc1")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-why__img">
              <img
                src={`${process.env.PUBLIC_URL}/img/why-copon.svg`}
                alt="why copon"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="home-numbers">
        <div className="row">
          <h1 className="has-line ">{t("home.numbers.title")}</h1>
          <div className="home-numbers__list">
            {[0, 0, 0, 0, 0].map((e, i) => (
              <div className="home-numbers__list--item">
                <div className="img">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/icon${i + 1}.svg`}
                    alt="icon"
                  />
                </div>
                <div className="text">
                  <h3>{t(`home.numbers.sub${i + 1}`)}</h3>
                  <h6>{t(`home.numbers.value${i + 1}`)}</h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="testmonials">
        <div className="row">
          <h1 className="has-line white">{t("home.test.title")}</h1>
          <div className="testmonials__content">
            <Slider {...settings_test}>
              {[0, 0, 0, 0, 0].map((e, i) => (
                <div key={i} className="slide-wrapper ">
                  <div className="testmonial">
                    <div className="text">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/top-quote.svg`}
                        alt="icon"
                        className="quote-top"
                      />
                      <p>{t("home.test.desc")}</p>
                      <img
                        src={`${process.env.PUBLIC_URL}/img/bottom-quote.svg`}
                        alt="icon"
                        className="quote-bottom"
                      />
                    </div>
                    <h2>{t("home.test.name")}</h2>
                    <h4>{t("home.test.job")}</h4>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="clients">
        <div className="row">
          <h1 className="has-line white">{t("home.clients.title")}</h1>
          <div className="clients__list">
            <Slider {...settings_clients}>
              {[1,2,3, 1,2,3,1,2,3,1,2,3,1,2,3,].map((e, i) => (
                <div key={i} className="slide-wrapper ">
                  <img
                 className="sponsor"
                    src={`${process.env.PUBLIC_URL}/img/client${e}.png`}
                    alt="icon"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="home-info">
        <div className="row">
          <div className="about__detail ">
                <div className="wrapper">
            <h1 className="about__detail--title has-line">{t("home.contact")}</h1>

                </div>
            <div className="about__detail--boxes">
              <div className="about__detail--box">
                <div className="icon">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/enail_con-main.svg`}
                    alt=""
                  />
                </div>
                <div className="content">
                  <h1>{t("contact.for_suggest")}</h1>
                  <a href="" className="btn btn-text btn-text--black">
                    info@e-copon.com
                  </a>
                </div>
              </div>

              <div className="about__detail--box">
                <div className="icon">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/helps-main.svg`}
                    alt=""
                  />
                </div>
                <div className="content">
                  <h1>{t("contact.for_support")}</h1>
                  <a href="" className="btn btn-text btn-text--black">
                    support@e-copon.com
                  </a>
                </div>
              </div>
              <div className="about__detail--box">
                <div className="icon">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/whatsapp_bussnis-main.svg`}
                    alt=""
                  />
                </div>
                <div className="content">
                  <h1>{t("contact.for_whatsapp")}</h1>
                  <a href="" className="btn btn-text btn-text--black">
                    00556849541646498
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
