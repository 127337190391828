import React from 'react'
import { useTranslation } from 'react-i18next'


export default function Rules() {
    const {t}= useTranslation()
    return (
        <div className="row">
                <div className="rules">
                    <h1>{t('about.rule_title')}
                    <strong>
                    ( eCopon - إي كوبون ) :
                    </strong>
                    </h1>
                    <p>{t('about.rule')
                    
                    }</p>
                </div>
        </div>
    )
}
