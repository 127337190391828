import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18Next from "i18next";

import Modal from "../../components/Modal";
import Select from "../../components/Select";
import UploadFile from "../../components/UploadFile";

export default function UploadFiles() {
  const [text, setText] = React.useState("");
  const [checked, setchecked] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(true);
  const [showPassword, setshowPassword] = React.useState(false);
  const [showPasswordConfirm, setshowPasswordConfirm] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const [phone, setphone] = React.useState("");

  const onchangephone = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      setphone(e.target.value);
    }
  };
  const { t } = useTranslation();
  return (
    <div className="competition">
      <Modal
        closeModal={closeModal}
        openModal={openModal}
        modalIsOpen={modalIsOpen}
        title={t("phase4.policypage.title")}
      >
        <div className="new-account-form ">
          <div className="policy">
            <div className="policy__content">
              {i18Next.languages[0] === "en" ? (
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Maiores id at, facilis, atque officiis, beatae fugit veritatis
                  <br />
                  ab quos unde dolores? Voluptatem assumenda non magni ut nulla
                  velit consequatur deserunt?
                  <br />
                  Lorem ipsum dolor sis, beatae fugit veritatis
                  <br />
                  ab quos unde dolores? Voluptatem assumenda non magni ut nulla
                  velit consequatur des
                  <br />
                  olor sit amet consectetur adipisicing elit. Maiores id at,
                  facilis, atque officiis, beatae fugit veritatis
                  <br />
                  ab quos unde d non magni ut nulla velit consequatur deserunt?
                  <br />
                  Lorem ipsum dolor siticiis, beatae fugit veritatis
                  <br />
                  ab quos unde dolores? Voluptatem unt?
                  <br />
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Maiores id at, facilis, atque officiis, beatae fugit veritatis
                  <br />
                  ab quos unde dolores? Voluptatem assumenda non magni ut nulla
                  velit consequatur deserunt?
                  <br />
                </p>
              ) : (
                <p>
                  لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,
                  <br />
                  سيت دو أيوسمود تيمبور
                  <br />
                  أنكايد يديو نتيوت لابوري ات دولار ماجنا أ
                  <br />
                  ليكيوا . يوت انيم أد مينيم فينايم ,كيواس نوستريد ديواس أيوتي
                  <br />
                  أريري دولار إن ريبريهينديرأيت فوليوبتاتي فيلايت أيسسي كايلليوم
                  دولار أيوفيجايت نيولا باراياتيور
                  <br />
                  . أيكسسيبتيور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا
                  يسكينج أليايت,سيت دو أيوسمود تيمبور
                  <br />
                  أنكايد يديو نتيوت لابوري ات دولار ماجن ا أليكيوا . يوت انيم أد
                  مينيم فينايم ,كيواس نوستريد
                  <br />
                  ديواس أيوتي أريري دولار إن
                  <br />
                  ريبريهينديرأيت فوليوبتاتي فيلايت أيسسي كايلليوم دولار
                  أيوفيجايت نيولا باراياتيور. أيكسسيبتيور لوريم ايبسوم دولار سيت
                  أميت ,كونسيكتيتور أدايبا ي
                  <br />
                  سكينج أليايت,سيت دو أيوسمود أنكايد يديو نتيوت لابوري ات دولار
                  ماجنا أليكيوا . يوت انيم
                  <br />
                  أد مينيم فينايم ,كيواس نوستريد ديواس أيوتي أريري دولار إن
                  ريبريهيندي رأيت فوليوبتاتي فيلايت أيسسي كايلليوم
                  <br />
                  دولار أيوفيجايت نيولا باراياتيور. أيكسسيبتيور
                </p>
              )}
            </div>
            <div className="policy__btns">
              <Link to="/phase4/upload" className="btn btn--yellow first">
                {t("phase4.btn_agree")}
              </Link>
              <Link to="/phase4/upload" className="btn btn--disabled reject">
                {t("phase4.cancel")}
              </Link>
            </div>
          </div>
        </div>
      </Modal>
      <div className="row">
        <div className="comp-details big" onClick={openModal}>
          <div className="comp-details__body">
            <div className="comp-details__body--data">
              <div className="comp-details__body--img"></div>
              <div className="comp-details__body--text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
