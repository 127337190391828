import React from "react";
import { useTranslation } from "react-i18next";

import Table from "../../components/Table";

export default function Participations() {
  const { t } = useTranslation();
  const [empty, setempty] = React.useState(false);
  const columns = [
    { name: "#" },
    { name: `${t("phase3.table.name")}` },
    { name: `${t("phase3.table.code")}` },
    { name: `${t("phase3.table.start")}` },

    { name: `${t("phase3.table.end")}` },
    { name: `${t("phase3.table.withdraw")}` },
  ];
  const data = [
    {
      "#": "1",
      [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
      [t("phase3.table.code")]: "45698522358",
      [t("phase3.table.start")]: "28/1/2021",
      [t("phase3.table.end")]: "28/1/2021",
      [t("phase3.table.withdraw")]: "28/1/2021",
    },
    {
      "#": "2",
      [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
      [t("phase3.table.code")]: "45698522358",
      [t("phase3.table.start")]: "28/1/2021",
      [t("phase3.table.end")]: "28/1/2021",
      [t("phase3.table.withdraw")]: "28/1/2021",
    },
    {
      "#": "3",
      [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
      [t("phase3.table.code")]: "45698522358",
      [t("phase3.table.start")]: "28/1/2021",
      [t("phase3.table.end")]: "28/1/2021",
      [t("phase3.table.withdraw")]: "28/1/2021",
    },
    {
      "#": "4",
      [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
      [t("phase3.table.code")]: "45698522358",
      [t("phase3.table.start")]: "28/1/2021",
      [t("phase3.table.end")]: "28/1/2021",
      [t("phase3.table.withdraw")]: "28/1/2021",
    },
    {
      "#": "5",
      [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
      [t("phase3.table.code")]: "45698522358",
      [t("phase3.table.start")]: "28/1/2021",
      [t("phase3.table.end")]: "28/1/2021",
      [t("phase3.table.withdraw")]: "28/1/2021",
    },
    {
      "#": "6",
      [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
      [t("phase3.table.code")]: "45698522358",
      [t("phase3.table.start")]: "28/1/2021",
      [t("phase3.table.end")]: "28/1/2021",
      [t("phase3.table.withdraw")]: "28/1/2021",
    },
    {
      "#": "7",
      [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
      [t("phase3.table.code")]: "45698522358",
      [t("phase3.table.start")]: "28/1/2021",
      [t("phase3.table.end")]: "28/1/2021",
      [t("phase3.table.withdraw")]: "28/1/2021",
    },
    {
      "#": "8",
      [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
      [t("phase3.table.code")]: "45698522358",
      [t("phase3.table.start")]: "28/1/2021",
      [t("phase3.table.end")]: "28/1/2021",
      [t("phase3.table.withdraw")]: "28/1/2021",
    },
    {
      "#": "9",
      [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
      [t("phase3.table.code")]: "45698522358",
      [t("phase3.table.start")]: "28/1/2021",
      [t("phase3.table.end")]: "28/1/2021",
      [t("phase3.table.withdraw")]: "28/1/2021",
    },
    {
      "#": "10",
      [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
      [t("phase3.table.code")]: "45698522358",
      [t("phase3.table.start")]: "28/1/2021",
      [t("phase3.table.end")]: "28/1/2021",
      [t("phase3.table.withdraw")]: "28/1/2021",
    },
  ];
  return (
    <div className="participations">
      <div className="row">
        <div className="participations__header">
          <h1 className="page-title no-margin">{t("phase3.participation")}</h1>
        </div>
        <div className="participations__wrapper">
          <div className="participations__table">
            <Table columns={columns} data={data} />
          </div>
        </div>
      </div>
    </div>
  );
}
