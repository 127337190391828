import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Modal from "../../components/Modal";

export default function CodeCheck() {
  const [text, setText] = React.useState("");
  const [checked, setchecked] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(true);

  const { t } = useTranslation();
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const [phone, setphone] = React.useState("");

  const onchangephone = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      setphone(e.target.value);
    }
  };

  return (
    <div className="">
      <Modal
        closeModal={closeModal}
        openModal={openModal}
        modalIsOpen={modalIsOpen}
        title={t("phase4.code_check")}
      >
        <div className="new-account-form">
          <form action="">
            <div className="form__group big-btm">
              <div className="row row--no-padding">
                <div className="pos-rel">
                  <input
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    type="text"
                    className={`form__input circle `}
                    placeholder={t("placeholders.code-input")}
                  />
                </div>
              </div>
            </div>
            <div className="form__group md-btm2">
              <div className="row row--no-padding">
                <div className="pos-rel">
                  <input
                    type="checkbox"
                    onChange={(e) => setchecked(e.currentTarget.checked)}
                    id="chekbox"
                    name="chekckbox"
                    checked={checked}
                    // value={checked}
                  />
                  <label for="chekbox">
                    <div className="check">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/check_white.svg`}
                        height="11px"
                        width="11px"
                      />
                    </div>
                    <span
                      className="checkbox-span"
                      style={{ fontSize: "1.5rem" }}
                    >
                      {t("phase4.agree")}
                    </span>{" "}
                    <Link
                      style={{ fontSize: "1.5rem" }}
                      to="/phase4/policy"
                      className="btn btn-text hovered"
                    >
                      {t("phase4.policy")}
                    </Link>
                  </label>
                </div>
              </div>
            </div>
            <div className="form__group ">
              <Link
                to="/phase4/reject"
                className={` btn bold custom-padding ${
                  !checked ? "btn--disabled" : "btn--green"
                } w-100`}
              >
                {t("phase4.code_check")}{" "}
              </Link>
            </div>
          </form>
        </div>
      </Modal>
      <div className="row">
        <div className="comp-details big" onClick={openModal}>
          <div className="comp-details__body">
            <div className="comp-details__body--data">
              <div className="comp-details__body--img"></div>
              <div className="comp-details__body--text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
