import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Copons() {
  const { t } = useTranslation();
  const card = (num) => (
    <div className={`copons__card  ${num === 7 ? "has-alert" : ""}`}>
      <div className="copons__card--img">
        <img src={`${process.env.PUBLIC_URL}/img/sympol.svg`} alt="card" />
      </div>
      <div className="copons__card--content">
        <h1>{t(`phase5.copon.title${num}`)}</h1>
        {num === 7 && <span className="flex-center">42</span>}
      </div>
    </div>
  );
  return (
    <div className="copons">
      <div className="row">
        <div className="copons__row ">
          {[0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((e, i) => (
            <Link
              to="/phase5/copons/resturants"
              key={i}
              className="copons__row--col "
            >
              {card(i)}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
