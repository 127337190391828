import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "1.5rem 1.5rem 1.4rem",
  borderWidth: 2,
  cursor: "pointer",
  marginBottom: ".4rem",
  borderRadius: 12,
  borderColor: "transparent",
  borderStyle: "solid",
  backgroundColor: "#f0f0f1",
  color: "#A3acb8",
  outline: "none",
  transition: "all .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
const thumbsContainer = {
  flex: 1,
  display: "flex",
  flexDirection: "row",
  position: "relative",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "1rem 1.7rem .9rem",
  borderWidth: 2,
  cursor: "pointer",
  marginBottom: ".4rem",
  borderRadius: 12,
  borderColor: "transparent",
  borderStyle: "solid",
  backgroundColor: "#f0f0f1",
  color: "#A3acb8",
  outline: "none",
  transition: "all .24s ease-in-out",
};

const thumb = {
  display: "flex",
  width: "100%",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  display: "inline-flex",
  borderRadius: 7,
  // border: '1px solid #eaeaea',
  // marginBottom: 8,
  // paddingLeft: 9,
  width: 86,
  height: 64,
  //padding: 4,
  boxSizing: "border-box",
};

const img = {
  display: "block",
  width: "100%",
  height: "100%",
  borderRadius: 7,
};

export default function StyledDropzone(props) {
  const [files, setFiles] = React.useState([]);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "image/*",
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner} className="upload-img">
        <img src={file.preview} style={img} alt={file.path} />
      </div>
      <div className="upload-info">
        <h2
          style={{
            fontSize: "1.4rem",
            lineHeight: "19px",
            marginBottom: ".5rem",
          }}
        >
          {file.path}
        </h2>
        <h2
          style={{
            fontSize: "1.4rem",
            lineHeight: "19px",
            marginBottom: ".5rem",
          }}
        >
          {formatBytes(file.size)}{" "}
        </h2>
      </div>
    </div>
  ));

  React.useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  const { t } = useTranslation();
  return (
    <div className="container">
      {files.length > 0 ? (
        <div className="upload-container" style={thumbsContainer}>
          {thumbs}
          <img
            className="upload-close"
            onClick={() => setFiles([])}
            style={{
              height: "27px",
              width: "20px",
              alignSelf: "flex-start",
              position: "absolute",
            }}
            src={`${process.env.PUBLIC_URL}/img/close2.svg`}
            alt=""
          />
        </div>
      ) : (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <img
            style={{ height: "27px", width: "34px" }}
            src={`${process.env.PUBLIC_URL}/img/upload-file.svg`}
            alt=""
          />
          <p style={{ marginTop: ".8rem", fontSize: "1.4rem" }}>
            {t("phase4.upload.heading")}
          </p>
        </div>
      )}
    </div>
  );
}
