import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function FinishForm() {
  const [text, setText] = React.useState("");
  const [email, setemail] = React.useState("");
  const [emailError, setemailError] = React.useState(false);
  const [showPassword, setshowPassword] = React.useState(false);

  const onchange = (e) => {
    setText(e.target.value);
  };
  const { t } = useTranslation();
  const onEmailChange = (e) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const valid = re.test(String(e.target.value).toLowerCase());
    valid ? setemailError(false) : setemailError(true);
    setemail(e.target.value);
  };

  return (
    <div className="phone-form">
      <h1 className="auth__title">{t("auth.finish.title")}</h1>
      <h3 className="auth__subtitle">{t("auth.finish.sub")}</h3>

      <div className="auth__box">
        <div className="form__group ">
          <div className="row row--no-padding">
            <div className="pos-rel">
              <input
                onChange={onchange}
                value={text}
                type="text"
                className={`form__input has-icon  circle ${
                  text.length > 20 ? "error" : text.length >= 1 ? "success" : ""
                }`}
                placeholder={t("placeholders.full-name")}
               
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/user_2.svg`}
                alt="cobon user name"
                className="form__icon"
              />
            </div>
          </div>
        </div>

        <div className="form__group big-btm">
          <div className="row row--no-padding">
            <div className="pos-rel">
              <input
                onChange={onEmailChange}
                value={email}
                type="email"
                className={`form__input has-icon  circle ${
                  emailError ? "error" : email.length >= 1 ? "success" : ""
                }`}
                placeholder={t("placeholders.email")}
               
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/email.svg`}
                alt="cobon user email"
                className="form__icon"
              />
            </div>
          </div>
        </div>
        <div className="form__group ">
          <Link
            to="/phase2/code"
            className={` btn bold custom-padding ${
              text.length < 1 ? "btn--disabled" : "btn--yellow has-shadow"
            } w-100`}
          >
            {t("auth.finish.continue")}{" "}
          </Link>
        </div>

        <div className="form__lines">
          <div></div>
          <span>{t("auth.or")}</span>
          <div></div>
        </div>

        <div className="form__group big-btm mg-top">
          <Link
            to="/phase2/register"
            className={` btn bold custom-padding ${
              text.length < 1 && email.length < 1
                ? "btn--green"
                : "btn--disabled"
            } w-100`}
          >
            {t("auth.finish.register")}{" "}
          </Link>
        </div>

        <div
          className="form__footer"
          style={{
            marginTop: "2px",
          }}
        >
          <div className="d-center">
            <div className=" text-right   col-padding-small flex-center">
              <p className="">{t("auth.finish.footer")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
