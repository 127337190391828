import React, { Component } from "react";
import Slider from "react-slick";
import tick from './../assets/tick.mp3'
export default class SlickGoTo extends React.Component {
  state = {
    slideIndex: 0,
    slideChoosen: 0,
    updateCount: 0
  };
  componentDidMount(){
    this.audio = new Audio(tick); 
    // this.slider.slickGoTo(0)

}
onClick=()=>{
    
   const inter=  setInterval(() => {
   
    // Play the sound.
    this.audio.play();
    // this.setState(state => ({ slideIndex: state.slideIndex + 1 }))
    this.slider.slickNext()
    
    }, 400);
    const ran = Math.floor(Math.random() * (12000 - 4000 + 1) + 4000);
    setTimeout(() => {
        clearInterval(inter);
        const ind =Math.floor(Math.random() * 10)
        const slideElement = this.slider.innerSlider.list.querySelector(`[data-index="${this.state.slideChoosen}"]`);
        console.log(slideElement);
    // this.slider.slickGoTo(ind)
        this.props.openSuccess(this.state.slideChoosen)
    }, ran);
}


  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 200,
      slidesToShow: 4,
      arrows:false,
      centerMode: true,
      responsive: [
       
        {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            
            }
          },
          {
            breakpoint: 460,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            
            }
          },
       
      ],
      rtl: false,
      slidesToScroll: 1,
       afterChange: (current) =>
       console.log(this.slider),
       beforeChange: (current, next) => this.setState({ slideChoosen: next })
    };
  
    return (
      <>
<div className="slider">
    
        <Slider 
        
        ref={slider => (this.slider = slider)} {...settings}>
          <div className="slide-wrapper" data-value="1">
          <img src={`${process.env.PUBLIC_URL}/img/grey-img.png`} alt="grey-img"/>
          
          </div>
          <div className="slide-wrapper" data-value="2">
             <img src={`${process.env.PUBLIC_URL}/img/grey-img.png`} alt="grey-img"/>
             
          </div>
          <div className="slide-wrapper" data-value="3">
             <img src={`${process.env.PUBLIC_URL}/img/grey-img.png`} alt="grey-img"/>
        
          </div >
          <div className="slide-wrapper" data-value="4">
             <img src={`${process.env.PUBLIC_URL}/img/grey-img.png`} alt="grey-img"/>
             
          </div>
          <div className="slide-wrapper" data-value="5">
             <img src={`${process.env.PUBLIC_URL}/img/grey-img.png`} alt="grey-img"/>
             
          </div>
          <div className="slide-wrapper" data-value="6">
             <img src={`${process.env.PUBLIC_URL}/img/grey-img.png`} alt="grey-img"/>
             
          </div>
          <div className="slide-wrapper" data-value="7">
             <img src={`${process.env.PUBLIC_URL}/img/grey-img.png`} alt="grey-img"/>
             
          </div>
          <div className="slide-wrapper" data-value="8">
             <img src={`${process.env.PUBLIC_URL}/img/grey-img.png`} alt="grey-img"/>
             
          </div>
          <div className="slide-wrapper" data-value="9">
             <img src={`${process.env.PUBLIC_URL}/img/grey-img.png`} alt="grey-img"/>
             
          </div>
          <div className="slide-wrapper" data-value="10">
             <img src={`${process.env.PUBLIC_URL}/img/grey-img.png`} alt="grey-img"/>
             
          </div>
          <div className="slide-wrapper" data-value="11">
             <img src={`${process.env.PUBLIC_URL}/img/grey-img.png`} alt="grey-img"/>
             
          </div>
        </Slider>
       
      </div>
     <div className="row">
     <button className="btn btn--yellow middle has-shadow" 
       onClick={this.onClick}
       >
           {this.props.btn_title}
           </button>
     </div>
      </>
    );
  }
}