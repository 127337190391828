import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Modal from "../../components/Modal";
import Select from "../../components/Select";

export default function LoginUser() {
  const [modalIsOpen, setIsOpen] = React.useState(true);

  const { t } = useTranslation();
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const [phone, setphone] = React.useState("");

  const onchangephone = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      setphone(e.target.value);
    }
  };
  const options = [
    {
      value: "966",
      label: (
        <div className="select__withImg">
          <img
            src={`${process.env.PUBLIC_URL}/img/flag.svg`}
            height="28px"
            width="28px"
          />
          <span>+966</span>{" "}
        </div>
      ),
    },
    {
      value: "967",
      label: (
        <div className="select__withImg">
          <img
            src={`${process.env.PUBLIC_URL}/img/flag.svg`}
            height="28px"
            width="28px"
          />
          <span>+966</span>{" "}
        </div>
      ),
    },
    {
      value: "956",
      label: (
        <div className="select__withImg">
          <img
            src={`${process.env.PUBLIC_URL}/img/flag.svg`}
            height="28px"
            width="28px"
          />
          <span>+966</span>{" "}
        </div>
      ),
    },
    {
      value: "926",
      label: (
        <div className="select__withImg">
          <img
            src={`${process.env.PUBLIC_URL}/img/flag.svg`}
            height="28px"
            width="28px"
          />
          <span>+966</span>{" "}
        </div>
      ),
    },
    {
      value: "964",
      label: (
        <div className="select__withImg">
          <img
            src={`${process.env.PUBLIC_URL}/img/flag.svg`}
            height="28px"
            width="28px"
          />
          <span>+966</span>{" "}
        </div>
      ),
    },
  ];

  return (
    <div className="competition">
      <Modal
        closeModal={closeModal}
        openModal={openModal}
        modalIsOpen={modalIsOpen}
        title={t("navbar:sign")}
      >
        <div className="new-account-form">
          <form action="">
            <div className="form__group md-btm2">
              <div className="row row--no-padding auth__group">
                <div className="input pos-rel">
                  <input
                    onChange={onchangephone}
                    value={phone}
                    type="text"
                    className={`form__input has-icon circle ${
                      phone.length > 9
                        ? "error"
                        : phone.length === 9
                        ? "success"
                        : ""
                    }`}
                    placeholder={t("placeholders.tele")}
                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/img/phone.svg`}
                    alt="cobon back"
                    className="form__icon"
                  />
                </div>
                <div className="select-wrapper">
                  <Select
                    className="form-select fixed"
                    classNamePrefix="select"
                    onChange={() => {}}
                    placeholder="اختر كود"
                    defaultValue={options[0]}
                    name="questions"
                    options={options}
                  />
                </div>
              </div>
            </div>
            <div className="form__group ">
              <Link
                to="/phase4/login-with-password"
                className={` btn bold custom-padding ${
                  !phone.length > 0 ? "btn--disabled" : "btn--green"
                } w-100`}
              >
                {t("navbar:sign")}{" "}
              </Link>
            </div>
          </form>
        </div>
      </Modal>
      <div className="row">
        <div className="comp-details big" onClick={openModal}>
          <div className="comp-details__body">
            <div className="comp-details__body--data">
              <div className="comp-details__body--img"></div>
              <div className="comp-details__body--text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
