import React from "react";
import { useTranslation } from "react-i18next";
/**
 * 
 * ! Labels are included in some inputs but not shown in UI, feel free to use it if you feel to
 */
export default function Form() {
  const { t } = useTranslation();

  const [text, setText] = React.useState("");
  const [num, setnum] = React.useState("");

  /**
   * 
   * * a function that allows only number values to be set to the Number input
   */
  const onchangeNum = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      setnum(e.target.value);
    }
  };
  const onchange = (e) => {
    setText(e.target.value);
  };
  return (
    <form method="POST" className="form font-0">
      <div className="form__group md-btm ">
        <div className=" d-center">
          <div className="form__radio-group ">
            <input
              type="radio"
              className="form__radio-input"
              id="support"
              name="type"
            />
            <label htmlFor="support" className="form__radio-label">
              <span className="form__radio-button"></span>
              {t("placeholders.support")}
            </label>
          </div>
          <div className="form__radio-group ">
            <input
              type="radio"
              className="form__radio-input"
              id="service"
              name="type"
            />
            <label htmlFor="service" className="form__radio-label">
              <span className="form__radio-button"></span>
              {t("placeholders.service")}
            </label>
          </div>
          <div className="form__radio-group ">
            <input
              type="radio"
              className="form__radio-input"
              id="info"
              name="type"
            />
            <label htmlFor="info" className="form__radio-label">
              <span className="form__radio-button"></span>
              {t("placeholders.info")}
            </label>
          </div>
        </div>
      </div>
      <h1 className="contact__detail--title">{t("contact.stay_in_contact")}</h1>

      <div className="form__group md-btm">
        <input
          onChange={onchange}
          value={text}
          type="text"
          className="form__input"
          placeholder={t("placeholders.address")}
          required
          id="name"
        />
        <label htmlFor="name" className="form__label">
          {t("placeholders.address")}
        </label>
      </div>
      <div className="form__group md-btm">
        <div className="row row--no-padding">
          <div className="col-1-of-2  col-padding-small">
            <input
              type="text"
              className="form__input"
              placeholder={t("placeholders.your-name")}
              required
              id="name"
            />
            <label htmlFor="name" className="form__label">
              {t("placeholders.your-name")}
            </label>
          </div>
          <div className="col-1-of-2 col-padding-small">
            <input
              type="email"
              className="form__input"
              placeholder={t("placeholders.email")}
              required
              id="emailForm"
            />
            <label htmlFor="emailForm" className="form__label">
              {t("placeholders.email")}
            </label>
          </div>
        </div>
      </div>
      <div className="form__group md-btm">
        <div className="row row--no-padding">
          <div className="col-1-of-2  col-padding-small">
            <input
              type="text"
              className="form__input"
              placeholder={t("placeholders.city")}
              required
              id="city"
            />
            <label htmlFor="name" className="form__label">
              {t("placeholders.city")}{" "}
            </label>
          </div>
          <div className="col-1-of-2 col-padding-small">
            <input
              onChange={onchangeNum}
              value={num}
              type="text"
              className="form__input"
              placeholder={t("placeholders.phone")}
              required
              id="phone"
            />
            <label htmlFor="phone" className="form__label">
              {t("placeholders.phone")}{" "}
            </label>
          </div>
        </div>
      </div>

      <div className="form__group md-btm2 display-in-bl">
        <textarea
          name="messageContent"
          id="message"
          className="form__input form__input--textarea"
          placeholder={t("placeholders.message")}
          required
        ></textarea>
        <label htmlFor="message" className="form__label">
          {t("placeholders.message")}{" "}
        </label>
      </div>
      <div className="form__group mg-top">
        <button
          className={`btn ${
            text.length < 1 || text.length > 9 ? "btn--disabled" : "btn--green"
          } w-100`}
        >
          {t("placeholders.send")}{" "}
        </button>
      </div>
    </form>
  );
}
