import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default withRouter(function Hero({ routes, title, location }) {
  const [brand, setbrand] = React.useState(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    routes.map((prop) => {
      // if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
      //   setbrand(i18next.languages[0]==='en'?prop.name : prop.rtlName)
      // }
      if (location.pathname === prop.layout + prop.path) {
        setbrand(i18next.languages[0] === "en" ? prop.name : prop.rtlName);
      }
    });
  });
  return (
    <div
      className="hero"
      style={{
        background: `url(${process.env.PUBLIC_URL}/img/hero.png)  no-repeat center bottom/cover`,

        height: "45vh",
        maxHeight: "290px",
      }}
    >
      <div className="hero__title big">
        <h1 className="">{brand}</h1>
        {brand && (
          <div className="title-line big">
            <div className="one"></div>
            <div className="two"></div>
            <div className="three"></div>
          </div>
        )}
      </div>
    </div>
  );
});
